.project_fr .nativeness {
  display: none;
}
.addMixUser {
  display: flex;
  align-items: center;
}
.addMixUser select {
  min-width: 200px;
  max-width: 250px;
}
.headerBlock ul.navbar-nav.navbar-right {
  border-top: 0;
}
.headerBlock ul.navbar-nav.navbar-right li.desctop {
  white-space: nowrap;
}
.headerBlock ul.navbar-nav.navbar-right li.desctop.schoolSelectContainer {
  display: inline-flex;
}
.headerBlock ul.navbar-nav.navbar-right li.desctop select,
.headerBlock ul.navbar-nav.navbar-right li.desctop vms-user-menu {
  display: inline-block;
}
.checkboxShowAllClasses {
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkboxShowAllClasses label,
.checkboxShowAllClasses input {
  margin: 0;
  font-weight: normal;
}
.blkmargin {
  margin-top: 15px;
}
.maintable {
  width: 75%;
}
.blockSchool {
  display: inline-block;
}
.listSchool {
  padding: 0;
  /*margin-right: 3%;*/
  float: left;
}
.selectForEmail {
  text-align: center;
  cursor: pointer;
}
.findteacher {
  margin-left: 10px;
}
.placeholderSearch {
  min-width: 30% !important;
}
.clas,
.clas2 {
  text-align: left;
  color: #29285E;
  font-size: 1.45em;
}
.listSchool .activemain {
  width: 100%;
  box-sizing: content-box;
  justify-content: center;
}
.wrapperButton.admin {
  width: 80%;
  height: 100%;
  float: left;
}
.wrapperButton.admin {
  border: 0.14em solid transparent;
}
.userHeaderAdmin {
  float: right;
}
.addcohort {
  display: table;
}
.wrapClassControl {
  width: 30%;
  display: flex;
}
.wrapClassControl span.sizelabelClass {
  white-space: nowrap;
}
.wrapClassControl select.form-control {
  width: 100%;
}
.buttonAdd,
.buttonRemove,
.allteacher {
  display: table-cell;
  vertical-align: top;
}
.wrapperBlockWidthTable {
  width: 65%;
}
.marginLeft {
  margin-right: 5px !important;
}
.buttonAdd {
  color: #29285E;
  font-weight: bold;
  border: none;
  outline: none;
  background: transparent;
  font-size: 16pt;
  cursor: pointer;
  width: 100%;
}
.buttonRemove {
  color: #29285E;
  font-weight: bold;
  border: none;
  outline: none;
  background: transparent;
  font-size: 16pt;
  cursor: pointer;
  width: 100%;
}
.addcohort {
  width: 100%;
}
.widthTd {
  text-align: left;
  width: 7em;
}
.wrapperCohort {
  width: 44% !important;
  display: inline-block;
  float: left;
}
.wrapperCohort p {
  text-align-last: center;
  color: #eaba1f;
  margin-bottom: 0;
}
.wrapperCohort select {
  width: 100%;
}
.wrapperCohort.two {
  float: right;
}
.selectMainTeacher {
  width: 52%;
}
.maintable .btn {
  float: right;
}
.linkaddteacher,
.linkaddteacher2 {
  color: #29285E;
  width: 100%;
  margin-bottom: 0.5em;
}
.linkaddteacher .dateTasks,
.linkaddteacher2 .dateTasks {
  float: right;
  font-size: 1.45em;
  width: 95%;
  text-align: center;
}
.linkaddteacher .wrapperAddText,
.linkaddteacher2 .wrapperAddText {
  border: none;
  outline: none;
  font-size: 13pt;
  margin: 10px 0;
  cursor: pointer;
  box-sizing: border-box;
  padding: 4px 10px;
  color: #fff;
  background-color: #337ab7;
  border-radius: 7px;
  margin: 0;
  padding: 2px 10px;
  /*float: right;*/
}
.linkaddteacher .wrapperAddText.class,
.linkaddteacher2 .wrapperAddText.class {
  margin-right: 10px;
}
.linkaddteacher2 {
  margin-bottom: 0;
}
.tableteacher {
  width: 100%;
  float: left;
}
.tableteacher .aligntitle {
  text-align: center !important;
  font-weight: bold;
}
.tableteacher .tdEdit {
  width: 4em;
}
.headers {
  color: #29285E;
  font-size: 1.2em;
  width: 70%;
  height: 26px;
  text-align: center;
  margin-bottom: 0.3em;
}
.request {
  margin-left: 2px;
}
.notSchool {
  text-align: center;
  font-size: 20px;
}
.notSchool p {
  margin-top: 2em;
}
.invalidPassword .ng-invalid {
  border: 1px solid red !important;
}
.checkForError {
  opacity: 0;
  font-size: 0;
  width: 0;
  margin: 0;
  height: 0;
  color: red;
  -webkit-transition: all 1s ease-out 0s;
  transition: all 1s ease-out 0s;
}
.checkForError.errorForForm {
  -webkit-transition: all 1s ease-out 0s;
  transition: all 1s ease-out 0s;
  opacity: 1;
  font-size: 17px;
  width: auto;
  margin: 0;
  height: auto;
}
.formAddStudent tr td:first-child {
  width: 40%;
}
.formAddStudent tr td:last-child {
  width: 60%;
}
.formAddStudent input,
.formAddStudent button,
.formAddStudent select {
  outline: none;
}
.formAddStudent .checkWorkstForCert {
  width: auto !important;
}
.formRegisterWorkstation tr td:first-child {
  width: 25%;
}
.formRegisterWorkstation input[name="workstationCenter"] {
  min-width: 200px;
}
.formRegisterWorkstation select[name="workstationTestSite"] {
  min-width: 150px;
}
.formRegisterWorkstation select[name="workstationNumber"] {
  min-width: 50px;
}
label {
  margin-bottom: 0;
  font-weight: normal;
  font-size: 1.17em;
}
.showTeachers {
  visibility: hidden;
}
