.content_wrapper.assistent {
  margin: 0;
  border-bottom: 0;
  width: 100%;
  padding: 0;
  height: 78vh;
}
.wrapperBlockLeftRight.assistent {
  background: #ffffff;
}
/* Chatbot */
#chatbot {
  display: flex;
  flex-direction: row;
}
#chatbot #chatbot_chatbox {
  width: 100%;
  height: 78vh;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 2em;
  position: relative;
}
#chatbot #chatbot_controlPanel {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80pt;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding-left: 40px;
}
#chatbot #chatbot_controlPanel .wrapperButtons {
  display: flex;
  width: 20%;
  justify-content: space-evenly;
}
#chatbot #chatbot_controlPanel .chatNotes_textArea {
  background: #f0f4f8;
}
#chatbot #chatbot_areaChat {
  position: absolute;
  max-height: 62vh;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 40px 19px 40px 40px;
  width: 98%;
  bottom: 100px;
  left: 0;
  box-sizing: border-box;
}
#chatbot #chatbot_areaChat .wrapperQuestion {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}
#chatbot #chatbot_areaChat .wrapperQuestion .chatbot_question {
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: pre-wrap;
}
#chatbot #chatbot_areaChat .wrapperQuestion .notes {
  width: 90%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  background: #c7edfc;
  padding: 10px;
  border-radius: 8px;
}
#chatbot #chatbot_areaChat .wrapperQuestion .notes .notesText {
  display: flex;
  width: 100%;
}
#chatbot #chatbot_areaChat .wrapperQuestion .notes .wrapperButtonsNotes {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
#chatbot #chatbot_areaChat .wrapperQuestion .notes .wrapperButtonsNotes svg {
  width: 50px;
  height: 31px;
  cursor: pointer;
  transform: scale(1);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
#chatbot #chatbot_areaChat .wrapperQuestion .notes .wrapperButtonsNotes svg path {
  fill: #29285E;
}
#chatbot #chatbot_areaChat .wrapperQuestion .notes .wrapperButtonsNotes svg.email {
  height: 36px;
  width: auto;
}
#chatbot #chatbot_areaChat .wrapperQuestion .notes .wrapperButtonsNotes svg.reminder {
  height: 32px;
}
#chatbot #chatbot_areaChat .wrapperQuestion .notes .wrapperButtonsNotes svg:hover {
  transform: scale(1.1);
}
#chatbot #chatbot_areaChat .wrapperQuestion .avatarNotes {
  background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 445 445" style="enable-background:new 0 0 445 445;" xml:space="preserve" width="512px" height="512px">\
                                        <g>\
	                                        <path d="M278.713,0H57.5v445h330V108.787L278.713,0z M287.5,51.213L336.287,100H287.5V51.213z M87.5,415V30h170v100h100v285H87.5z" fill="rgb(41, 40, 94)"/>\
	                                        <rect x="122.5" y="192.5" width="200" height="30" fill="rgb(41, 40, 94)"/>\
	                                        <rect x="122.5" y="332.5" width="200" height="30" fill="rgb(41, 40, 94)"/>\
	                                        <rect x="122.5" y="262.5" width="200" height="30" fill="rgb(41, 40, 94)"/>\
                                        </g>\
                                        </svg>');
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 10px 0 0;
}
#chatbot .dialogReminder {
  position: fixed;
  width: 600px;
  height: 300px;
  top: 30%;
  left: 50%;
  z-index: 1001;
  margin-left: -300px;
  background: #ffffff;
  border: 2px solid #29285E;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 11px;
  justify-content: space-around;
}
#chatbot .dialogReminder div {
  display: flex;
  justify-content: center;
  align-items: center;
}
#chatbot .dialogReminder div p {
  width: 23%;
  font-size: 16px;
  margin: 0;
}
#chatbot .dialogReminder div input,
#chatbot .dialogReminder div textarea {
  outline: none;
  width: 60%;
  resize: none;
}
#chatbot .dialogReminder div.dialogButtons button {
  outline: none;
  border: 1px solid #29285E;
  background: #29285E;
  color: #ffffff;
  min-width: 90px;
  cursor: pointer;
  margin-right: 10px;
}
#chatbot .dialogBack {
  position: fixed;
  background: #d6d6d638;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}
#chatbot #chatbot_textArea,
#chatbot #chatbot_textArea2 {
  border: solid #29285E 1px;
  color: #000;
  background: #fff;
  resize: none;
  font-size: 1em;
  width: 80%;
  line-height: 40px;
  height: 40px;
  padding: 3px 5px;
  box-sizing: content-box;
  overflow-x: hidden;
  outline: none;
  border-radius: 8px;
}
#chatbot #chatbot_textArea::-webkit-placeholder,
#chatbot #chatbot_textArea2::-webkit-placeholder,
#chatbot #chatbot_textArea::placeholder,
#chatbot #chatbot_textArea2::placeholder {
  line-height: 2.7em;
  font-style: italic;
}
#chatbot .chatbot_question,
#chatbot .chatbot_answer {
  margin-bottom: 10px;
  padding: 8px 10px;
  box-sizing: border-box;
  border-radius: 8px;
}
#chatbot .chatbot_answer {
  background: #f4e8f2;
}
#chatbot .chatbot_answer.youtube,
#chatbot .chatbot_answer.book {
  white-space: normal !important;
}
#chatbot .chatbot_answer.image {
  display: flex;
  justify-content: space-between;
}
#chatbot .chatbot_answer.image p {
  margin: 0;
}
#chatbot .chatbot_answer.image img {
  width: 150px;
  object-fit: contain;
  cursor: pointer;
}
#chatbot .chatbot_answer.image .button {
  background: #29285E;
  color: #ffffff;
  min-width: 123px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
#chatbot .chatbot_answer.button {
  display: flex;
  justify-content: space-between;
}
#chatbot .chatbot_answer.button p {
  margin: 0;
}
#chatbot .chatbot_answer.button a {
  background: #29285E;
  color: #ffffff;
  min-width: 123px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#chatbot .chatbot_answer .youtube {
  background: #fff;
  display: inline-block;
  border-radius: 18%;
  padding: 0.7em 1.4em;
}
#chatbot .chatbot_answer .youtube svg {
  width: 90px;
  height: 90px;
  vertical-align: middle;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition-timing-function: linear;
  -webkit-transition-duration: 300ms;
}
#chatbot .chatbot_answer .youtube svg:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition-timing-function: linear;
  -webkit-transition-duration: 300ms;
}
#chatbot .chatbot_answer input {
  padding: 0.3em 1em;
  margin: 0.3em;
  cursor: pointer;
}
#chatbot .chatbot_answer input:hover {
  transition: transform 0.2s;
  transform: scale(1.1);
}
#chatbot .chatbot_question {
  background: #a9a9be;
  text-align: right;
}
#chatbot #chatbot_chatbox ::-webkit-scrollbar {
  width: 8pt;
  background: #dfdfdf;
}
#chatbot #chatbot_chatbox ::-webkit-scrollbar-thumb {
  background-color: #29285E;
}
#chatbot .wrapButtonSvg {
  height: 40px;
  padding: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  box-shadow: #ff000000 0px 0px 1px 1px;
  border-radius: 50%;
  padding: 6px 8px;
  box-sizing: border-box;
  transform: scale(1.45);
}
#chatbot .wrapButtonSvg svg {
  width: auto;
  height: 100%;
}
#chatbot .wrapButtonSvg svg path {
  fill: #29285E;
}
#chatbot .wrapButtonSvg:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  cursor: pointer;
}
#chatbot .wrapButtonSvg.start {
  box-shadow: #ff000000 0px 0px 1px 1px;
}
#chatbot .wrapButtonSvg.start #svg_34,
#chatbot .wrapButtonSvg.start #svg_31,
#chatbot .wrapButtonSvg.start #svg_35,
#chatbot .wrapButtonSvg.start #svg_1,
#chatbot .wrapButtonSvg.start #svg_2,
#chatbot .wrapButtonSvg.start #svg_3,
#chatbot .wrapButtonSvg.start #svg_4,
#chatbot .wrapButtonSvg.start #svg_5,
#chatbot .wrapButtonSvg.start #svg_6,
#chatbot .wrapButtonSvg.start #svg_7,
#chatbot .wrapButtonSvg.start #svg_8,
#chatbot .wrapButtonSvg.start #svg_9,
#chatbot .wrapButtonSvg.start #svg_10,
#chatbot .wrapButtonSvg.start #svg_11,
#chatbot .wrapButtonSvg.start #svg_12,
#chatbot .wrapButtonSvg.start #svg_13,
#chatbot .wrapButtonSvg.start #svg_14,
#chatbot .wrapButtonSvg.start #svg_15,
#chatbot .wrapButtonSvg.start #svg_16,
#chatbot .wrapButtonSvg.start #svg_17,
#chatbot .wrapButtonSvg.start #svg_18,
#chatbot .wrapButtonSvg.start #svg_19,
#chatbot .wrapButtonSvg.start #svg_20,
#chatbot .wrapButtonSvg.start #svg_21,
#chatbot .wrapButtonSvg.start #svg_22,
#chatbot .wrapButtonSvg.start #svg_23,
#chatbot .wrapButtonSvg.start #svg_24,
#chatbot .wrapButtonSvg.start #svg_25 {
  animation-name: chatbot_record;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
#chatbot .wrapButtonSvg.speak {
  box-shadow: #ff0000 0px 0px 1px 1px;
  -webkit-animation: blink5 2s linear infinite;
  animation: blink5 2s linear infinite;
}
#chatbot .wrapButtonSvg.speak #svg_34,
#chatbot .wrapButtonSvg.speak #svg_31,
#chatbot .wrapButtonSvg.speak #svg_35,
#chatbot .wrapButtonSvg.speak #svg_1,
#chatbot .wrapButtonSvg.speak #svg_2,
#chatbot .wrapButtonSvg.speak #svg_3,
#chatbot .wrapButtonSvg.speak #svg_4,
#chatbot .wrapButtonSvg.speak #svg_5,
#chatbot .wrapButtonSvg.speak #svg_6,
#chatbot .wrapButtonSvg.speak #svg_7,
#chatbot .wrapButtonSvg.speak #svg_8,
#chatbot .wrapButtonSvg.speak #svg_9,
#chatbot .wrapButtonSvg.speak #svg_10,
#chatbot .wrapButtonSvg.speak #svg_11,
#chatbot .wrapButtonSvg.speak #svg_12,
#chatbot .wrapButtonSvg.speak #svg_13,
#chatbot .wrapButtonSvg.speak #svg_14,
#chatbot .wrapButtonSvg.speak #svg_15,
#chatbot .wrapButtonSvg.speak #svg_16,
#chatbot .wrapButtonSvg.speak #svg_17,
#chatbot .wrapButtonSvg.speak #svg_18,
#chatbot .wrapButtonSvg.speak #svg_19,
#chatbot .wrapButtonSvg.speak #svg_20,
#chatbot .wrapButtonSvg.speak #svg_21,
#chatbot .wrapButtonSvg.speak #svg_22,
#chatbot .wrapButtonSvg.speak #svg_23,
#chatbot .wrapButtonSvg.speak #svg_24,
#chatbot .wrapButtonSvg.speak #svg_25 {
  animation-name: chatbot_record;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@-webkit-keyframes blink5 {
  0% {
    box-shadow: #800000 0px 0px 1px 1px;
  }
  25% {
    box-shadow: #f97a7a 0px 0px 1px 1px;
  }
  50% {
    box-shadow: #fbd6d6 0px 0px 1px 1px;
  }
  75% {
    box-shadow: #f97a7a 0px 0px 1px 1px;
  }
  100% {
    box-shadow: #800000 0px 0px 1px 1px;
  }
}
@keyframes blink5 {
  0% {
    box-shadow: #800000 0px 0px 1px 1px;
  }
  25% {
    box-shadow: #f97a7a 0px 0px 1px 1px;
  }
  50% {
    box-shadow: #fbd6d6 0px 0px 1px 1px;
  }
  75% {
    box-shadow: #f97a7a 0px 0px 1px 1px;
  }
  100% {
    box-shadow: #800000 0px 0px 1px 1px;
  }
}
#chatbot .chatbot {
  display: flex;
  align-items: flex-start;
}
#chatbot .chatbot .avatar {
  background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 16 16">\
                                    <path fill="rgb(41, 40, 94)" d="M3 11.2c0 0.1 0 0.1 0 0 0 0.1 0 0.1 0 0 0 0 0 0 0 0z"/>\
                                    <path fill="rgb(41, 40, 94)" d="M8.3 1c-4.4 0-8.3 2.6-8.3 5.6 0 2 1.1 3.7 3 4.7 0 0 0 0 0 0s0 0.1 0 0.1c-0.1 1.3-0.9 1.7-0.9 1.7l-1.8 0.9h2c2.5 0 4.3-1.1 5.1-1.9 0.3 0 0.6 0 0.8 0 4.3 0 7.8-2.5 7.8-5.6s-3.4-5.5-7.7-5.5zM8.2 11.1c-0.3 0-0.7 0-0.9 0h-0.2l-0.2 0.2c-0.5 0.5-1.6 1.4-3.3 1.7 0.3-0.5 0.5-1.1 0.5-2v-0.3l-0.3-0.1c-1.8-0.9-2.8-2.3-2.8-4 0-2.4 3.5-4.6 7.3-4.6 3.7 0 6.7 2 6.7 4.6 0 2.4-3.1 4.5-6.8 4.5z"/>\
                                    <path fill="rgb(41, 40, 94)" d="M6 7c0 0.552-0.448 1-1 1s-1-0.448-1-1c0-0.552 0.448-1 1-1s1 0.448 1 1z"/>\
                                    <path fill="rgb(41, 40, 94)" d="M9 7c0 0.552-0.448 1-1 1s-1-0.448-1-1c0-0.552 0.448-1 1-1s1 0.448 1 1z"/>\
                                    <path fill="rgb(41, 40, 94)" d="M12 7c0 0.552-0.448 1-1 1s-1-0.448-1-1c0-0.552 0.448-1 1-1s1 0.448 1 1z"/>\
                                    </svg>');
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 10px 0 0;
}
#chatbot .chatbot .chatbot_answer {
  margin-bottom: 10px;
  padding: 8px 10px;
  box-sizing: border-box;
  width: 90%;
  white-space: pre-wrap;
}
/*#chatbot input {
    outline: none;
    padding: 0;
    background: #29285E;
    color: #fff;
    border: none;
    user-select: none;
    text-align: center;
    vertical-align: middle;
}*/
#chatbot #chatbot_containerButtonText {
  width: 100%;
  height: 100%;
}
@keyframes chatbot_record {
  0% {
    fill: red;
  }
  50% {
    fill: red;
  }
  100% {
    fill: red;
  }
}
/* -----------------------------*/
#dots {
  position: absolute;
  top: -1px;
  width: 80px;
  height: 29px;
}
#dots #dot1 {
  animation: load 1s infinite;
}
#dots #dot2 {
  animation: load 1s infinite;
  animation-delay: 0.2s;
}
#dots #dot3 {
  animation: load 1s infinite;
  animation-delay: 0.4s;
}
@keyframes load {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
