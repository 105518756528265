.languaBooksCourse tr {
  text-align: initial;
}
.languaBooksCourse table {
  width: initial;
  border-collapse: initial;
  border: initial;
}
.languaBooksCourse table tr {
  text-align: initial;
}
.languaBooksCourse table td,
.languaBooksCourse table th {
  border: initial;
  padding: initial;
  cursor: initial;
}
.languaBooksCourse table .titleTable {
  background: initial;
  color: initial;
  font-weight: initial;
  text-align: initial;
}
.languaBooksCourse table tr:nth-child(odd) {
  background-color: initial;
}
.languaBooksCourse table tr:nth-child(even) {
  background-color: initial;
}
.languaBooksCourse table tr:hover,
.languaBooksCourse table tr:nth-child(odd):hover,
.languaBooksCourse table tr:nth-child(even):hover {
  background: initial;
}
.languaBooksCourse table .col8 {
  white-space: initial;
}
.languaBooksCourse select,
.languaBooksCourse input {
  width: initial;
  border: initial;
  margin-bottom: initial;
}
.languaBooksCourse h1 {
  color: initial;
  margin: initial;
}
