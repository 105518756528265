.student-report.row {
  margin: 0;
}
.student-report.row .form-group {
  display: flex;
  align-items: center;
  width: 100%;
  /*font-size: 1.15em;*/
  font-size: 11pt;
}
.student-report.row .form-group.reports {
  justify-content: space-between;
}
.student-report.row .form-group.student .select-class-container {
  width: 30%;
}
.student-report.row .form-group.student .select-class-container .select-class-caption {
  width: 30%;
}
.student-report.row .form-group.student .select-class-container .select-class-control {
  width: 70%;
}
.student-report.row .form-group.student .select-student-container {
  width: 30%;
}
.student-report.row .form-group.student .select-student-container .select-student-caption {
  width: 30%;
}
.student-report.row .form-group.student .select-student-container .select-student-control {
  width: 70%;
}
.student-report.row .form-group.student .history-control {
  width: 40%;
  justify-content: flex-start;
  box-sizing: border-box;
  padding-left: 1em;
}
.student-report.row .form-group.class .form-item-wrapper.select-class-container {
  width: 33.33%;
}
.student-report.row .form-group.class .form-item-wrapper.select-class-container.first {
  min-width: 400px;
}
.student-report.row .form-group.class .form-item-wrapper.select-class-container .select-class-caption {
  width: 35%;
  text-align: center;
}
.student-report.row .form-group.class .form-item-wrapper.select-class-container .select-class-control {
  width: 65%;
}
.student-report.row .form-group.class .form-item-wrapper.select-class-container.run-button {
  justify-content: flex-end;
}
.student-report.row .form-group.class .form-item-wrapper.select-class-container.description {
  width: 40%;
}
.student-report.row .form-group.class .form-item-wrapper.run-button-control {
  width: 10%;
  justify-content: flex-end;
  padding-left: 1em;
}
.student-report.row .form-group.class .form-item-wrapper.run-button-control.report {
  width: auto;
}
.student-report.row .form-group.class .form-item-wrapper.run-button-control.report-mobile {
  display: none;
}
.student-report.row .form-group.class .select-class-container .select-class-control .ui-select-container {
  width: 100%;
}
.student-report.row .form-group.class .select-class-container .select-class-control .ui-select-container .username {
  background-color: lightgray;
}
.student-report.row .form-group.class .select-class-container .select-class-control .ui-select-container .ui-select-match-text span {
  width: auto;
}
.student-report.row .form-group.class .select-class-container .select-class-control .ui-select-container .ui-select-choices {
  width: auto;
  max-height: 260px;
  right: initial !important;
}
.student-report.row .form-group.class .select-class-container .select-class-control .ui-select-container .ui-select-choices .username {
  display: inline-block;
  min-width: 124px;
}
.student-report.row .form-group.book .select-class-container {
  width: 33%;
  display: flex;
}
.student-report.row .form-group.book .select-class-container .select-class-caption {
  width: 31%;
}
.student-report.row .form-group.book .select-class-container .select-class-control {
  width: 65%;
}
.student-report.row .form-group.book .select-student-container {
  width: 33%;
  display: flex;
}
.student-report.row .form-group.book .select-student-container .select-student-caption {
  width: 30%;
}
.student-report.row .form-group.book .select-student-container .select-student-control {
  width: 70%;
}
.student-report.row .form-group.book .course-id-container {
  width: 33%;
}
.student-report.row .form-group.book .course-id-container .course-id-caption {
  width: 30%;
}
.student-report.row .form-group.book .course-id-container .course-id-control {
  width: 60%;
}
.student-report.row .form-group.book .run-button-control {
  width: 90%;
  justify-content: flex-end;
}
.student-report.row .form-item-wrapper {
  padding: 0;
  float: none;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.student-report.row .form-item-wrapper2 {
  padding: 0;
  float: none;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-left: 20px;
}
.projectAdmin .student-report.row .form-item-wrapper {
  justify-content: flex-start;
}
.breakTitle {
  word-break: break-word;
  padding: 0.5em 1em 0.5em;
}
.reportMenu .active {
  color: #29285E;
}
.mobile .reportMenu {
  color: gray;
}
.mobile .reportMenu li {
  cursor: pointer;
}
.reportDataErrors {
  color: red;
}
.tabCurriculum.mobile.sreport_mobile {
  height: 7em !important;
  z-index: 20;
}
.reportMenu.mobile {
  position: absolute;
  margin: 0;
  padding: 0;
  text-align: left;
  bottom: 0;
  right: 35px;
  z-index: 50;
  cursor: pointer;
}
.reportMenu.mobile.disabled {
  opacity: 0.6;
  pointer-events: none;
  cursor: default;
}
.listStudentReport {
  position: absolute;
  top: 0;
  height: 20px;
  width: 20px;
  border: 1px solid;
  right: 0;
}
.tabCurriculum.categories {
  position: relative;
}
.btnDetails {
  width: 1.5em;
  height: 1.5em;
  margin: auto;
  cursor: pointer;
  background: url("images/icon_detail.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.tabCurriculum2 {
  border-width: 0.1em;
  width: 101%;
  border-color: lightgray #fcfcfc lightgray lightgray;
  font-size: 1.15em;
  transition-duration: 0.1s;
  transition-timing-function: linear;
  transition-property: background;
  cursor: pointer;
  /*font-family: "Open Sans", sans-serif;*/
  color: gray;
  border: 0.1em solid transparent;
  margin-left: -0.01em;
}
.tabCurriculum2 path {
  fill: gray;
}
.tabCurriculum2:hover path {
  fill: #29285E;
}
.tabCurriculum2:hover .text1 {
  color: #29285E;
}
.tabCurriculum2.activeTebCur {
  border-right: 1px solid transparent;
  border-top: 0.1em solid lightgray;
  border-bottom: 0.1em solid transparent;
  border-left: 0.1em solid lightgray;
  border-radius: 0.4em 0 0 0.4em;
  color: #29285E;
}
.tabCurriculum2.activeTebCur path {
  fill: #29285E;
}
.textArea,
.textArea[disabled] {
  opacity: 1;
  background: #ffffff;
  resize: none;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
}
.textarea {
  width: 98% !important;
}
.firstrow {
  justify-content: space-between !important;
}
.firstrow .select-student-container {
  width: 63% !important;
}
.run.btn.btn-primary {
  margin: 0 0.2em;
}
.curriculum {
  overflow-x: auto;
  font-size: 12pt;
  max-height: 600px;
  overflow-y: auto;
  padding: 0;
}
.curriculum div.string:first-child .cell {
  border-top: 1px solid #3e3d6e;
}
.curriculum div.rowdata:nth-child(odd) {
  background: #F1F1F1;
  color: #333333;
}
.curriculum div.rowdata:nth-child(odd):hover {
  background-color: #a9a9be;
}
.curriculum div.rowdata:nth-child(even) {
  background: #ffffff;
  color: #333333;
}
.curriculum div.rowdata:nth-child(even):hover {
  background-color: #a9a9be;
}
.curriculum .string {
  display: table-row;
}
.curriculum .string .cell {
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #3e3d6e;
  display: table-cell;
  border-right: 1px solid #3e3d6e;
  padding: 2px 5px;
  box-sizing: border-box;
  min-width: 100px;
  font-size: 11pt;
}
.curriculum .string .cell.center {
  text-align: center;
}
.curriculum .string .cell.left {
  text-align: left;
}
.curriculum .string .cell.right {
  text-align: right;
}
.curriculum .string div:first-child {
  border-left: 1px solid #3e3d6e;
}
.curriculum .string .header {
  background: #3e3d6e;
  color: #ffffff;
  font-size: 13pt;
}
.loadReport {
  height: 400px;
  width: 100px;
  margin: auto;
}
.loadReport .wrap {
  background-image: url("images/load.svg");
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  text-align: center;
}
.select-class-caption.delCenter {
  justify-content: flex-start !important;
  width: initial !important;
}
.marg {
  margin-right: 8px;
}
.curriculums.tabCurriculum {
  height: 8em;
}
@media (max-width: 1300px) {
  .student-report.row .form-group.class .date-container {
    width: 31%;
  }
  .student-report.row .form-group.class .date-container .date-control input {
    font-size: 12px;
  }
  .student-report.row .form-group.class .run-button-control {
    width: 6%;
  }
}
@media (max-width: 768px) {
  .form-group {
    display: block!important;
    margin: auto;
    width: 80%!important;
  }
  .form-group.book .select-class-container,
  .form-group.book .select-student-container,
  .form-group.book .course-id-container {
    display: block!important;
    width: 100%!important;
  }
  .form-group.book .select-class-container *,
  .form-group.book .select-student-container *,
  .form-group.book .course-id-container * {
    width: 100%!important;
  }
  .form-group.class .select-class-container,
  .form-group.class .date-container,
  .form-group.class .run-button-control {
    display: block!important;
    width: 100%!important;
    padding: 0 !important;
  }
  .form-group.class .select-class-container *,
  .form-group.class .date-container *,
  .form-group.class .run-button-control * {
    width: 100%!important;
  }
  .form-group.student .select-class-container,
  .form-group.student .select-student-container {
    display: block!important;
    width: 100%!important;
  }
  .form-group.student .select-class-container *,
  .form-group.student .select-student-container * {
    width: 100%!important;
  }
  .student-report.row .form-group.class .date-container .date-control input {
    font-size: 14px;
  }
  .form-item-wrapper {
    display: block!important;
    text-align: left!important;
    height: auto!important;
    margin-bottom: 0.5em;
  }
  .form-item-wrapper.run-button-control.report.report-desctop {
    display: none !important;
  }
  .form-item-wrapper.run-button-control.report.report-mobile {
    display: block;
  }
  .form-item-wrapper.run-button-control.report.report-mobile .run.btn.btn-primary {
    margin: 0 0 2px 0;
  }
}
.btn.btn-primary.excel {
  margin: 0 0.2em;
}
/*--------------------------------------------------*/
.tableCustom {
  font-size: 11pt;
  width: 100%;
  border: 1px solid #3e3d6e;
  border-top: 0;
}
.tableCustom .rowCustom {
  display: flex;
}
.tableCustom .rowCustom .cell {
  width: 15%;
  border-right: 1px solid #3e3d6e;
  border-bottom: 1px solid #3e3d6e;
  padding: 2px 6px;
  word-wrap: break-word;
  word-break: break-word;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /*  &.details{
                width: 8%;
            }*/
}
.tableCustom .rowCustom .cell.name {
  width: 15%;
}
.tableCustom .rowCustom .cell.bookRJ {
  width: 13%;
}
.tableCustom .rowCustom .cell.w6 {
  width: 6%;
}
.tableCustom .rowCustom .cell.w7 {
  width: 7%;
}
.tableCustom .rowCustom .cell.w8 {
  width: 8%;
}
.tableCustom .rowCustom .cell.w9 {
  width: 9%;
}
.tableCustom .rowCustom .cell.w10 {
  width: 10%;
}
.tableCustom .rowCustom .cell.w12 {
  width: 12%;
}
.tableCustom .rowCustom .cell.w18 {
  width: 18%;
}
.tableCustom .rowCustom .cell.w19 {
  width: 19%;
}
.tableCustom .rowCustom .cell.w28 {
  width: 28%;
}
.tableCustom .rowCustom .cell.w22 {
  width: 22%;
}
.tableCustom .rowCustom .cell.login {
  width: 10%;
}
.tableCustom .rowCustom .cell.idnumber {
  width: 11%;
}
.tableCustom .rowCustom .cell.book {
  width: 10%;
}
.tableCustom .rowCustom .cell.listening,
.tableCustom .rowCustom .cell.qscore,
.tableCustom .rowCustom .cell.reading,
.tableCustom .rowCustom .cell.rscore,
.tableCustom .rowCustom .cell.gemstone {
  width: 8%;
}
.tableCustom .rowCustom .cell.nameCR {
  width: 30%;
}
.tableCustom .rowCustom .cell.countCR {
  width: 10%;
}
.tableCustom .rowCustom .cell.listeningCR,
.tableCustom .rowCustom .cell.readingCR,
.tableCustom .rowCustom .cell.rscoreCR,
.tableCustom .rowCustom .cell.qscoreCR {
  width: 8%;
}
.tableCustom .rowCustom .cell.attempts {
  width: 8%;
}
.tableCustom .rowCustom .cell.firstcolumn {
  flex-direction: column;
}
.tableCustom .rowCustom .cell:last-child {
  border-right: none;
}
.tableCustom .rowCustom .cell .icon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  margin: 0 3px;
  cursor: pointer;
  background-size: 100% 100%;
}
.tableCustom .rowCustom.header {
  background: #3e3d6e;
  color: #ffffff;
  font-size: 11pt;
  text-align: center;
}
.tableCustom .rowCustom.bodyTable1:nth-child(2n) {
  background: #F1F1F1;
}
.tableCustom .rowCustom.bodyTable1:nth-child(2n):hover {
  background-color: #a9a9be;
  cursor: pointer;
}
.tableCustom .rowCustom.bodyTable1:nth-child(2n + 1) {
  background: #ffffff;
}
.tableCustom .rowCustom.bodyTable1:nth-child(2n + 1):hover {
  background-color: #a9a9be;
  cursor: pointer;
}
.tableCustom .rowCustom.bodyTable1 .cell.name,
.tableCustom .rowCustom.bodyTable1 .cell.login,
.tableCustom .rowCustom.bodyTable1 .cell.idnumber,
.tableCustom .rowCustom.bodyTable1 .cell.book,
.tableCustom .rowCustom.bodyTable1 .cell.nameCR,
.tableCustom .rowCustom.bodyTable1 .cell.loginCR,
.tableCustom .rowCustom.bodyTable1 .cell.gemstone,
.tableCustom .rowCustom.bodyTable1 .cell.left {
  justify-content: left;
}
.tableCustom .rowCustom.bodyTable1 .cell.rscore,
.tableCustom .rowCustom.bodyTable1 .cell.qscore,
.tableCustom .rowCustom.bodyTable1 .cell.attemptTable,
.tableCustom .rowCustom.bodyTable1 .cell.countCR,
.tableCustom .rowCustom.bodyTable1 .cell.listeningCR,
.tableCustom .rowCustom.bodyTable1 .cell.readingCR,
.tableCustom .rowCustom.bodyTable1 .cell.rscoreCR,
.tableCustom .rowCustom.bodyTable1 .cell.qscoreCR,
.tableCustom .rowCustom.bodyTable1 .cell.listening,
.tableCustom .rowCustom.bodyTable1 .cell.reading,
.tableCustom .rowCustom.bodyTable1 .cell.attempts,
.tableCustom .rowCustom.bodyTable1 .cell.right {
  justify-content: right;
}
.tableCustom .rowCustom:last-child .cell {
  border-bottom: none;
}
