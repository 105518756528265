.blockProfile {
  position: absolute;
  right: 0;
  background: #FFFFFF;
  box-shadow: inset 0em 0em 0.3em #817C7C;
  padding: 0.4em 0em;
  width: 11em;
  box-sizing: border-box;
  line-height: 2em;
  cursor: pointer;
  z-index: 22;
  top: 3em;
}
.blockProfile .firstBlock {
  color: #34495e;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.blockProfile .secondBlock,
.blockProfile .thirdBlock {
  color: #7b8a8b;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.blockProfile .thirdBlock:last-child {
  border-bottom: 0.1em solid transparent;
}
.blockProfile .line {
  background: #cac8c8;
  height: 1px;
}
.blockProfile a {
  display: block;
  text-decoration: none;
  padding: 0 1em;
}
.blockProfile a:hover {
  background: #29285E;
  color: #ffffff;
}
.blockProfile p {
  margin: 0;
}
.firstBlock svg {
  margin-right: 0.35em;
  width: 1.7em;
  height: 1.7em;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: top;
}
.firstBlock svg path {
  fill: #ffffff;
}
.userMenu li:hover svg path,
.userMenu li:hover svg circle {
  fill: #ffffff;
}
.userMenu svg {
  width: 20px;
  height: 20px;
}
.userMenu svg path,
.userMenu svg circle {
  fill: #7B8A8B;
}
.mobileView .userMenu svg {
  width: 20px;
  height: 20px;
}
.mobileView .userMenu svg path,
.mobileView .userMenu svg circle {
  fill: #ffffff;
}
.dropdown.username {
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-toggle.username-icon.dropdown-toggle {
  margin: 0 !important;
  padding-top: 0.3em !important;
  cursor: pointer;
}
.firstBlock.dropdown-toggle.user-container.profile,
.firstBlock.user-container.profile.mobile {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.firstBlock.dropdown-toggle.user-container.profile.noprofile,
.firstBlock.user-container.profile.mobile.noprofile {
  cursor: default;
}
.mobileSchools {
  display: flex;
  flex-direction: column;
}
