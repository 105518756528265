.popup {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #29285E24;
}
.inPopupWindow {
  width: 50em;
  /* height: 50%; */
  position: fixed;
  top: 4em;
  left: 30em;
  background: #ffffff;
  padding: 2em;
  border-radius: 0.4em;
}
.inPopupWindow .centerLabel {
  display: flex;
  align-items: center;
}
.inPopupWindow .centerLabel .label {
  font-size: 1.15em;
  font-weight: normal;
  text-align: right;
  color: #333;
  width: 10em;
  margin-bottom: 0;
}
.inPopupWindow ._submit {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
  margin-bottom: 0;
}
.inPopupWindow .myclose {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.inPopupWindow .myclose svg path {
  fill: #29285E;
}
