.profileClass {
  width: 100%;
}
.profileClass .msg {
  display: inline-block;
  color: red;
}
.profileClass .nameClass {
  font-size: 1em;
  width: 70%;
}
.profileClass .selectMainTeacher {
  font-size: 1em;
  width: 70%;
}
.profileClass .blockTeachers {
  width: 100%;
}
.profileClass .buttonSave {
  width: 50%;
  text-align: left;
}
.profileClass .buttonSave button {
  font-size: 13pt;
  background: #29285E;
  border: none;
  outline: none;
  color: #ffffff;
  cursor: pointer;
  width: 15%;
  margin-bottom: 1em;
}
.profileClass .buttonSave button[disabled] {
  opacity: 0.5;
}
.dspFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 6px 0;
}
.dspFlex > div:first-child {
  width: 40%;
}
.dspFlex > div:last-child {
  width: 60%;
}
.dspFlex > div:last-child input,
.dspFlex > div:last-child textarea {
  width: 100%;
}
.dspFlex .errorForForm .btn-default {
  border-color: red !important;
}
.dspFlex .btn-default {
  color: #000000 !important;
  border-color: #ccc !important;
}
.dspFlex .btn-default-focus {
  box-shadow: none !important;
  outline: none !important;
}
.dspFlex .form-control {
  box-shadow: none !important;
  padding-top: 0px;
  padding-left: 4px;
  padding-bottom: 0px;
  padding-right: 0px;
  height: 26px;
}
.dspFlex textarea.form-control {
  height: auto;
}
