#uploadFile {
  display: none;
}
.BtnBackEmail {
  background: #29285E;
  border: none;
  outline: none;
  color: #ffffff;
  font-size: 14px;
  padding: 6px 12px;
  box-sizing: border-box;
  margin: 2px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.blockAttach .attachment {
  display: inline-block;
  margin-right: 12px;
  margin-top: 4px;
  margin-bottom: 4px;
}
.top-panel {
  display: table;
  width: 100%;
  margin-bottom: 10px;
}
.top-panel .row {
  display: table-row;
}
.top-panel .row .cell {
  display: table-cell;
}
.top-panel .warning {
  color: #29285E;
  font-size: 17px;
  vertical-align: middle;
  margin-left: 8px;
}
.wrapperMailBlock .ui-select-match-item.btn.btn-default.btn-xs.btn-primary {
  color: #ffffff !important;
}
.collapsedRecipients {
  max-height: 2.1em;
  overflow-y: hidden;
}
div.collapseBtn {
  text-align: right;
}
div.collapseBtn button {
  font-size: 16px;
  position: relative;
  padding: 0.1em 0.5em;
}
div.collapseBtn button.collapsed {
  top: -1.8em;
}
div.body {
  margin-top: 16px;
}
