.incorrect {
  color: red;
}
#uploadFile {
  display: none;
}
.wrpperBtnImport {
  display: flex;
  justify-content: space-between;
}
.loadingImport {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 27px;
  z-index: 113;
}
.hideImportList {
  visibility: hidden;
}
.descriptionImport {
  margin: 20px;
}
.descriptionImport ul {
  padding: 0;
}
