.mainListClasses .maintable {
  width: 100%;
  float: left;
  overflow-y: auto;
  overflow-x: hidden;
}
.mainListClasses.adminWorkstations .computerRegistration {
  padding: 0;
}
.mainListClasses.adminWorkstations .computerRegistration .rightBlock {
  padding: 6px;
}
.mainListClasses.adminWorkstations .computerRegistration .register2 {
  padding-left: 16px;
}
.mainListClasses.adminWorkstations .maintable {
  margin-top: 16px;
}
.hideButton {
  display: none;
}
.widthColumn {
  width: 8% !important;
}
textarea.noresize {
  resize: none;
}
