.wrapperCourseShelve .expired {
  color: red;
}
.disabled {
  pointer-events: none;
  opacity: 0.6;
}
.red {
  color: red;
}
.schooladmin .wrapperDesc {
  width: unset;
}
.schooladmin.assignBlocks.licenses .linkaddteacher {
  width: 75%;
  margin-bottom: -1em;
}
.schooladmin.assignBlocks.licenses .linkaddteacher2 {
  width: 75%;
}
