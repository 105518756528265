@font-face {
  font-family: Gautami;
  src: url(fonts/gautamib.ttf);
}
.disabled {
  pointer-events: none;
}
.hidetab {
  display: none !important;
}
#posDiamonds svg {
  width: 1.45em;
  height: 1.4em;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: top;
  margin-right: 0.3em;
}
.btnOpenCert {
  display: flex;
  justify-content: center;
  align-items: center;
}
.rang {
  width: 1.7em;
  margin-left: 0.2em;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat !important;
  background-position: 50% 50%;
}
.mycomments {
  width: 1.1em;
  height: auto;
  background-size: contain;
  background-repeat: no-repeat;
}
.mycomments svg {
  position: absolute;
  top: 0.18em;
  width: 19px;
}
.mycomments.bot svg {
  width: 22px;
  top: 0.2em;
}
.mycomments path {
  fill: #ffffff;
}
.comment.mycomments path {
  fill: #29285E;
}
.shoppingCart {
  background-image: url('data:image/svg+xml;utf8, <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 475.084 475.085" style="enable-background:new 0 0 475.084 475.085;" xml:space="preserve">\
<g>	<g>	<path d="M365.446,401.998c0,10.092,3.579,18.702,10.711,25.834c7.132,7.139,15.749,10.711,25.845,10.711    c10.081,0,18.698-3.572,25.83-10.711c7.139-7.132,10.711-15.742,10.711-25.834s-3.568-18.702-10.711-25.841    c-7.132-7.132-15.749-10.704-25.83-10.704c-10.096,0-18.713,3.572-25.845,10.704C369.025,383.296,365.446,391.906,365.446,401.998    z" fill="rgb(255,255,255)"/>\
		<path d="M469.658,78.51c-3.618-3.617-7.898-5.426-12.848-5.426H113.918c-0.193-1.331-0.621-3.756-1.287-7.277    c-0.666-3.523-1.188-6.329-1.569-8.425c-0.383-2.087-1.093-4.611-2.142-7.561c-1.047-2.952-2.284-5.286-3.711-6.995    c-1.425-1.718-3.328-3.189-5.708-4.43c-2.378-1.233-5.092-1.853-8.136-1.853H18.276c-4.952,0-9.234,1.812-12.85,5.424    C1.809,45.583,0,49.868,0,54.816s1.809,9.231,5.426,12.847c3.619,3.617,7.902,5.424,12.85,5.424h58.237l50.532,234.976    c-0.378,0.76-2.329,4.373-5.852,10.848c-3.521,6.475-6.328,12.135-8.42,16.988c-2.093,4.859-3.14,8.616-3.14,11.279    c0,4.948,1.809,9.232,5.424,12.854c3.621,3.606,7.902,5.421,12.851,5.421h18.272h255.815h18.261c4.948,0,9.232-1.814,12.847-5.421    c3.62-3.621,5.427-7.905,5.427-12.854c0-4.949-1.807-9.233-5.427-12.847c-3.614-3.614-7.898-5.428-12.847-5.428h-262.66    c4.57-9.138,6.854-15.222,6.854-18.268c0-1.909-0.238-4.004-0.715-6.283s-1.047-4.805-1.713-7.569    c-0.667-2.752-1.093-4.799-1.283-6.133l298.077-34.831c4.753-0.575,8.658-2.614,11.703-6.14c3.046-3.518,4.565-7.562,4.565-12.133    V91.363C475.082,86.415,473.278,82.132,469.658,78.51z" fill="rgb(255,255,255)"/>\
		<path d="M109.632,401.998c0,10.092,3.567,18.702,10.706,25.834c7.141,7.139,15.75,10.711,25.841,10.711    c10.085,0,18.699-3.572,25.835-10.711c7.139-7.132,10.71-15.742,10.71-25.834s-3.568-18.702-10.71-25.841    c-7.137-7.132-15.75-10.704-25.835-10.704c-10.09,0-18.704,3.572-25.841,10.704C113.203,383.296,109.632,391.906,109.632,401.998z    " fill="rgb(255,255,255)"/>\
	</g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>');
  width: 1.7em;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-left: 0.5em;
  display: block;
}
.shoppingCart div {
  background: #ff0000;
  color: #ffffff;
  border-radius: 2em;
  font-size: 0.6em;
  width: 1.3em;
  height: 1.3em;
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notificationsNumber {
  background: #ff0000;
  color: #ffffff;
  border-radius: 2em;
  font-size: 0.7em;
  text-align: center;
  position: absolute;
  left: 53%;
  top: -0.2em;
  /* padding-left: 0.3em; */
  /* padding-right: 0.3em; */
  min-width: 1em;
}
.rang span {
  font-size: 0.9em;
}
.rang.gold {
  background-size: cover;
  background: url("images/rang_gold.svg");
}
.rang.silver {
  background-size: cover;
  background: url("images/rang_silver.svg");
}
.rang.bronze {
  background-size: cover;
  background: url("images/rang_bronze.svg");
}
.rang.more {
  display: none;
}
.noLanguacard {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  font-family: Arial;
  width: 85%;
}
.noLanguacard button {
  outline: none;
  border: none;
  background: #29285E;
  color: #ffffff;
  padding: 5px 50px;
  margin-bottom: 30px;
}
