.select_criterion {
  display: inline-block;
  margin: 1px 10px;
  list-style: none;
  padding: 2px 8px;
  box-sizing: border-box;
}
.select_criterion.added,
.select_criterion:hover {
  /*background: linear-gradient(to bottom, #5f442a, #9b724b);*/
  background: #29285E;
  color: #fff;
  border-radius: 5px;
}
.select_criterion input[type=checkbox] {
  display: none;
}
.wrapperBlockLeftRight.search {
  flex-direction: column;
}
.search_criterions li {
  margin-left: -5px;
  background: #f2f2f2;
  border-radius: 5px;
}
.resultSearch {
  width: 100%;
  box-sizing: border-box;
  padding: 2em 2em 0;
  display: table-cell;
  vertical-align: top;
  border-radius: 0 0.4em 0.4em 0;
}
.content_wrapper {
  width: 80%;
  margin: 0 auto 1em;
  padding: 1em;
  box-sizing: border-box;
  border-bottom: 0.1em solid lightgray;
}
.search_button_container {
  text-align: center;
}
.content_wrapper .advanced_search {
  font-weight: bold;
  font-size: 1.323em;
  margin-bottom: 0.3em;
  text-align: center;
}
.input_controls {
  width: 100%;
}
.input_controls strong {
  width: 16%;
}
div.input_controls input[type="text"] {
  background: none repeat scroll 0 0 #f2f2f2;
  border-image: none;
  border-radius: 3px;
  border: 1px solid #c8c8c8;
  padding: 0 0 0 3px;
  height: 2em;
}
.input_controls div {
  display: inline-block;
}
.input_controls > div {
  margin-bottom: 1em;
  display: flex;
  align-items: center;
}
button.get_results {
  border: none;
  outline: none;
  font-size: 20px;
  padding: 5px 30px;
  background: #075190;
  color: #FFFFFF;
  border-radius: 5px;
  cursor: pointer;
}
.wrapperDirectInfo {
  width: 85%;
  margin: auto;
}
.wrapperDirectInfo .msgMoreCourses {
  text-align: center;
  font-size: 1.3em;
}
.search_criterions {
  width: 100%;
  margin: auto;
}
.search_criterions strong.topic {
  display: inline-block;
  padding: 0;
}
.search_criterions ul li label {
  margin: 0;
  font-weight: normal;
  cursor: pointer;
}
.search_criterions ul {
  margin-top: 0;
  margin-bottom: 0;
}
.search_criterions p {
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 8em;
  text-align: right;
}
ul.topics li label {
  font-weight: normal;
}
@media (max-width: 990px) {
  .input_controls div,
  .input_controls > div {
    display: inline-block;
  }
  .search_criterions p {
    margin-left: 1em;
    text-align: left;
  }
}
