.container_canvas {
  /*width: 50em !important;*/
  margin: 0 auto;
  height: 28em !important;
}
.container_canvas .chart-container {
  width: 100%;
  height: 100%;
}
#pronun {
  background: #ffffff;
}
.progress-bar {
  background: #29285E;
}
.shadow_result {
  margin: 0.3em;
  height: 3.5em;
  width: 4em;
  float: left;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 33 33" style="enable-background:new 0 0 33 33;" xml:space="preserve"><g><path d="M16.5,30.5c-0.147,0-0.288-0.065-0.382-0.178l-16-19C-0.032,11.144-0.04,10.886,0.1,10.7l6-8 c0.094-0.126,0.243-0.2,0.4-0.2h20c0.157,0,0.306,0.074,0.4,0.2l6,8c0.14,0.186,0.132,0.444-0.018,0.622l-16,19 C16.788,30.435,16.647,30.5,16.5,30.5z M1.139,10.982L16.5,29.224l15.361-18.242L26.25,3.5H6.75L1.139,10.982z" fill="rgb(1,156,20)"></path></g></svg>');
  background-repeat: no-repeat;
  background-size: contain;
}
.container_pronun {
  width: 50em;
  margin: 0 auto;
}
.nativness {
  overflow: auto;
}
.table {
  border-collapse: collapse;
  margin: 0.3em 0;
  width: 100%;
}
.table th {
  background-color: #a9a9be;
  border: 0.1em solid #000000 !important;
  border-collapse: collapse;
}
.table th,
.table td {
  width: 3.4em;
  min-width: 3.4em;
  padding: 0.6em 0.2em;
  text-align: center;
}
.table td {
  background-color: #ffffff;
  border: 0.1em solid #000000 !important;
  border-collapse: collapse;
}
.table td span {
  font-size: 1.2em;
}
.name_table {
  font-size: 1.3em;
}
.wrapperTables {
  width: 100%;
  margin: 0.8em 0em;
}
.scoreWord {
  background-color: #a9a9be;
  border-radius: 1em;
  width: 1.2em;
  min-width: 1.2em;
  height: 1.2em;
  text-align: center;
  position: relative;
  bottom: 1.3em;
  left: 5em;
}
h1 {
  color: #4E361F;
  font-size: 1.6em;
  margin: 0.35em 0;
}
.name_table div {
  width: 100%;
}
.shadow_result.result {
  height: 3.5em;
  width: 4em;
  float: left;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 54.391 54.391" style="enable-background:new 0 0 54.391 54.391;" xml:space="preserve"><g><polygon points="0.285,19.392 24.181,49.057 13.342,19.392 " fill="rgb(234,186,31)"></polygon><polygon points="15.472,19.392 27.02,50.998 38.795,19.392 " fill="rgb(234,186,31)"></polygon><polygon points="29.593,49.823 54.105,19.392 40.929,19.392 " fill="rgb(234,186,31)"></polygon><polygon points="44.755,3.392 29.297,3.392 39.896,16.437 " fill="rgb(234,186,31)"></polygon><polygon points="38.094,17.392 27.195,3.979 16.297,17.392 " fill="rgb(234,186,31)"></polygon><polygon points="25.094,3.392 9.625,3.392 14.424,16.525 " fill="rgb(234,186,31)"></polygon><polygon points="7.959,4.658 0,17.392 12.611,17.392 " fill="rgb(234,186,31)"></polygon><polygon points="54.391,17.392 46.424,4.645 41.674,17.392 " fill="rgb(234,186,31)"></polygon></g></svg>');
  background-size: contain;
  background-repeat: no-repeat;
}
/*.desctop{
    display:block;
}

.mobile{
    display:none;
}*/
@media (max-width: 768px) {
  .container_pronun {
    width: 100%;
  }
  .container_canvas .chart-container {
    height: auto;
  }
  .container_canvas {
    height: auto!important;
  }
  .mobile {
    height: auto!important;
  }
  .shadow_result {
    margin: 0.3em 0;
    width: 3em;
  }
}
