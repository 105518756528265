.tabCurriculum.cohorts {
  display: flex;
  align-items: center;
  justify-content: start;
  text-align: center;
  min-height: 4em;
}
.levelImg {
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
}
.levelImg.imgLevel {
  align-items: start;
  text-align: left;
}
.levelImg img {
  width: 40px;
}
