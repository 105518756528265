.buy {
  background: #eeac33;
  font-weight: bold;
  font-size: 0.9em;
}
.buy.subscription {
  opacity: 0.5;
  pointer-events: none;
}
.textSubscription {
  position: absolute;
  background: #0c6dc2;
  margin: 0;
  color: #ffffff;
  padding: 0 4px;
  font-weight: bold;
  top: 12px;
}
.linkpdf {
  font-style: italic;
  font-weight: normal;
  color: #29285E;
}
.product_name {
  margin-bottom: 0.2em;
  height: 3em;
  overflow: hidden;
}
.product_price {
  font-weight: bold;
}
.wrapperCourse.market {
  height: 19em;
}
.variantTitle,
.period {
  display: inline-block;
  margin-bottom: 1em;
}
.wrapInfoParams div .variantTitle {
  width: 15%;
}
.shoppingOptions .title {
  display: inline-block;
}
.shoppingOptions .links {
  display: inline-table;
  padding-left: 33px;
}
.shoppingOptions .links div.shopLink {
  display: table-row;
  padding-bottom: 4px;
  padding-left: 20px;
}
.shoppingOptions .links div.shopLink .linkName {
  display: table-cell;
  padding-right: 117px;
}
.shoppingOptions .links div.shopLink .linkName span {
  font-size: 17px;
  font-weight: 600;
}
.shoppingOptions .links div.shopLink .linkButton {
  display: table-cell;
}
.shoppingOptions .links div.shopLink .linkButton button.btn.buy {
  padding-top: 0;
  padding-bottom: 0;
}
.wrapperBlockLeftRight.cart {
  display: block;
}
.control-wrapper {
  margin-bottom: 1em;
}
.variant input[type="date"] {
  width: 9em;
  /*float: right;*/
}
.period {
  vertical-align: top;
}
.periodDays {
  color: #333 !important;
}
.periodDays span {
  width: auto !important;
  color: #333 !important;
}
.periodDaysCount {
  color: #333 !important;
}
.period input[type=date] {
  width: 9em;
}
.editFields {
  position: absolute;
  bottom: 0;
  right: 0;
}
.editFields input[type="date"] {
  height: 1.7em;
}
.dateStartCaption {
  margin-top: 0.1em;
  display: inline-block;
}
.licenseDateContainer {
  height: 7em;
}
.period span {
  font-weight: bold;
}
.period label {
  font-weight: normal;
}
.colorfile {
  color: #29285E;
  margin-right: 0.5em;
}
.default_image {
  background-size: contain;
}
.default_tests {
  background-image: url('data:image/svg+xml;utf8,\
               <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 60 60" style="enable-background:new 0 0 60 60; " xml:space="preserve">\
                    <g><path d="M57.5,58h-1v-2.36c0-3.534-1.845-6.748-4.827-8.64c2.981-1.892,4.827-5.105,4.827-8.64V36h1c0.553,0,1-0.447,1-1   s-0.447-1-1-1h-1h-1h-14h-1h-1c-0.553,0-1,0.447-1,1s0.447,1,1,1h1v2.36c0,3.534,1.845,6.748,4.827,8.64 \
            c-2.981,1.892-4.827,5.105-4.827,8.64V58h-1c-0.553,0-1,0.447-1,1s0.447,1,1,1h1h1h13h2h1c0.553,0,1-0.447,1-1S58.053,58,57.5,58z    M42.5,38.36V36h12v2.36c0,3.319-2.033,6.298-5.197,7.64h-1.605C44.533,44.658,42.5,41.68,42.5,38.36z M42.5,58v-2.36   c0-3.319,2.033-6.298,5.197-7.64h1.605c3.164,1.342,5.197,4.32,5.197,7.64V58H42.5z" fill="rgb(41, 40, 94)"></path>\
         <path d="M18.5,22h20V12h-20V22z M21.5,14h14v2h-14V14z M21.5,18h14v2h-14V18z" fill="rgb(41, 40, 94)"></path><path d="M35.5,38.36V31h12V0h-35h-1h-9h-1v60h1h9h1h23v-4.36c0-3.247,1.199-6.279,3.262-8.64C36.699,44.639,35.5,41.607,35.5,38.36   z M4.5,58V2h5v56H4.5z M16.5,24V10h24v14H16.5z" fill="rgb(41, 40, 94)"></path></g>\
                </svg>');
  background-size: contain;
}
.default_books {
  background-image: url('data:image/svg+xml;utf8,\
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" \
         xml:space="preserve" width="512px" height="512px">\
                    <g><path d="M44,12H24v10h20V12z M41,20H27v-2h14V20z M41,16H27v-2h14V16z" fill="rgb(41, 40, 94)" /><path d="M17,0H7v60h10h36V0H17z M15,58H9V2h6V58z M46,24H22V10h24V24z"\
         fill="rgb(41, 40, 94)" /></g>\
                </svg>');
  background-size: contain;
}
.default_curriculums {
  background-image: url('data:image/svg+xml;utf8,\
               <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="112px" height="90px" viewBox="0 0 97.391 97.391" style="enable-background:new 0 0 97.391 97.391; " xml:space="preserve">\
                        <g>\
                    <path d="M0,7.573h17.5v8.75H0V7.573z M0,19.823h17.5v59.501H0V19.823z M3.208,68.312c0,3.021,2.447,5.47,5.469,5.47   c3.021,0,5.469-2.447,5.469-5.47s-2.448-5.47-5.469-5.47C5.655,62.843,3.208,65.293,3.208,68.312z M0,89.824h17.5v-7H0V89.824z    M21,81.6v1.225v7h22.75v-8.268c-0.929-0.664-3.815-2.232-10.5-2.232C26.913,79.324,22.866,80.744,21,81.6z M21,31.257   c1.866,0.854,5.913,2.276,12.25,2.276c6.643-0.001,9.536-1.55,10.5-2.245v-6.215H21V31.257z M21,35.046V77.81   c2.534-0.948,6.592-1.985,12.25-1.985c5.105,0,8.417,0.854,10.5,1.767V35.268c-2.083,0.91-5.395,1.765-10.5,1.765   C27.592,37.031,23.534,35.994,21,35.046z M80.859,7.18l-12,2.462l1.76,8.571l12-2.462L80.859,7.18z M95.279,77.469l-11.998,2.463   L71.32,21.643l12-2.462L95.279,77.469z M91.116,67.08c-0.607-2.957-2.972-4.971-5.275-4.498c-2.307,0.475-3.688,3.258-3.077,6.215   c0.606,2.957,2.969,4.973,5.274,4.498C90.346,72.822,91.725,70.041,91.116,67.08z M95.984,80.893l-12.002,2.464l1.408,6.854   l12-2.463L95.984,80.893z M49,16.323h17.5v-8.75H49V16.323z M49,89.824h17.5v-7H49V89.824z M49,70.574h17.5V28.573H49V70.574z    M49,79.324h17.5v-5.25H49V79.324z M49,25.073h17.5v-5.25H49V25.073z" fill="rgb(41, 40, 94)"></path>\
                </g>\</svg>');
  background-size: contain;
}
.msg_select {
  color: red;
}
.block_cart_product {
  display: table;
  width: 98%;
  margin: auto;
  min-height: 14em;
  position: relative;
  margin-bottom: 1.5em;
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 1.5em;
}
.wrap_img_cart {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.wrap_img_cart .default_image.cart {
  background-size: contain;
  height: 6em;
  background-repeat: no-repeat;
  border: 0;
  width: 50%;
}
.wrap_img_library {
  width: 10em;
  height: 10em;
  cursor: pointer;
  box-shadow: 0 0 0.3em #605F5F;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrap_img_library .default_image.library {
  background-size: contain;
  height: 5em;
  background-repeat: no-repeat;
  border: 0;
  width: 50%;
}
.wrap_img_info {
  width: 17em;
  height: 17em;
  object-fit: contain;
  cursor: pointer;
  box-shadow: 0 0 0.3em #605F5F;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.wrap_img_info .default_image.library {
  background-size: contain;
  height: 8em;
  background-repeat: no-repeat;
  border: 0;
  width: 50%;
}
.cart_img {
  /*width: 11%;*/
  width: 13em;
  height: 13em;
  display: table-cell;
  vertical-align: middle;
  box-shadow: 0 0 0.3em #605F5F;
}
.cart_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.info_cart {
  display: table-cell;
  padding-left: 1em;
}
.info_cart .fullname {
  font-size: 1.2em;
  float: left;
}
.info_cart .price {
  float: right;
  font-size: 1.2em;
  font-weight: bold;
}
.info_cart .price span {
  margin-right: 2em;
}
.info_cart .variant {
  float: none;
  clear: both;
  vertical-align: bottom;
  font-size: 1.2em;
  position: absolute;
  bottom: 0.5em;
}
.info_cart .variant span {
  color: #8e8e8e;
  width: 6em;
  display: inline-block;
}
.wrap_button_cart {
  text-align: right;
  margin: 1em 2em;
}
.wrap_button_cart .continue,
.wrap_button_cart .proceed {
  outline: none;
  border: 1px solid #ffb100;
  background: transparent;
  border-radius: 0.3em;
  font-size: 1.1em;
  font-weight: bold;
  padding: 0.2em 1em;
}
.wrap_button_cart .proceed {
  border: 2px solid #ffb100;
  background: #ffb100;
}
.wrap_button_cart.empty {
  text-align: center;
  margin-top: 5em;
}
.wrap_button_cart.empty p {
  margin: 2em;
  font-size: 1.14em;
}
.translationMarket.desc p {
  width: 100%;
}
.desc2 {
  max-height: 14em;
  overflow-y: auto;
}
.desc2 p {
  width: 100% !important;
}
.wrap_cart_total {
  text-align: right;
  margin: 1em 2em;
  font-size: 1.2em;
}
.wrap_cart_total .total {
  color: #8e8e8e;
}
.wrap_cart_total .value_total {
  font-weight: bold;
}
.title_cart {
  width: 98%;
  margin: 1em auto;
  font-size: 1.2em;
  font-weight: bold;
}
.title_cell_cart {
  width: 98%;
  margin: 1em auto;
  color: #8e8e8e;
  font-size: 1.1em;
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 1.5em;
}
.title_cell_cart p:first-child {
  float: left;
}
.title_cell_cart p:last-child {
  float: right;
  margin-right: 2em;
}
.translationMarket {
  margin-top: 1em;
}
.titleTasktop.market {
  width: 60%;
}
