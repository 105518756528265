.wrapBlockUiSelect .notification {
  width: 90%;
}
.notification.ui-select-container.ng-invalid .ui-select-toggle,
.notification[text-angular].ng-invalid .ta-editor {
  border-color: red !important;
}
form[name=notifForm] div.title {
  padding-top: 0.3em;
}
form[name=notifForm] .row {
  margin-bottom: 15px;
}
.max2rows {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.maintable.notifications {
  width: 80%;
}
.ta-scroll-window > .ta-bind {
  min-height: 420px;
}
