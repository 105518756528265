.notfound {
  font-size: 1.3em;
  text-align: center;
}
.wrapperNavigateParent {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.msgFindUser {
  font-size: 13pt;
  text-align: center;
}
