.wrapperCoursLessonPlan {
  display: inline-block;
  vertical-align: top;
  width: 10em;
  height: 10em;
  margin: 0 1.2em 0;
  margin-bottom: 4em;
  position: relative;
}
.wrapperCoursLessonPlan img {
  width: 10em;
  height: 10em;
  cursor: pointer;
  box-shadow: 0em 0em 0.3em #605F5F;
}
.wrapperCoursLessonPlan .titleBook {
  position: absolute;
  top: 35%;
  left: 0px;
  height: 34%;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: 'Open Sans';
  cursor: pointer;
}
.wrapperCoursLessonPlan .titleBook .title {
  font-size: 17px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}
.wrapperCoursLessonPlan .titleBook .idbook {
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 16px;
  color: #000000;
  font-weight: bold;
}
