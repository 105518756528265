.wrapperDescription.rCard.spellWord .spellword {
  width: 100%;
  height: 100%;
  padding: 0;
  text-transform: uppercase;
  transition: color 0.5s ease-in;
}
.wrapperDescription.rCard.spellWord .spellword.finished {
  color: green!important;
  border-color: green!important;
}
.wrapperDescription.rCard.spellWord .spellword span {
  font-size: 1.7em;
}
.wrapperDescription.rCard.spellWord .alert {
  margin-top: 7px!important;
  width: 100%!important;
}
.wrapperDescription.rCard.spellWord .languaCardInstruction {
  margin-top: -1em;
  width: 100%;
  text-align: center;
  font-size: 1.2em;
  float: left;
}
.wrapperDescription.rCard.spellWord .buttonscards {
  margin-top: -0.7em;
}
.wrapperDescription.rCard.spellWord .cardsWrapper .cardsParsedInfoWrapper .courseWrapper {
  margin-top: 0.5em;
}
.wrapperDescription.rCard.spellWord .dictionary_display > div {
  width: 8%;
  margin-left: 0.4em;
  height: 1.5em;
}
@media (max-width: 900px) and (orientation: landscape) {
  .wrapperDescription.rCard.spellWord .alert {
    margin-top: 2px!important;
    padding: 0px!important;
  }
  .wrapperDescription.rCard.spellWord .dictionary_display > div {
    width: 10%;
    margin-left: 0.1em;
    height: 1.3em;
  }
}
