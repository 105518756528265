.wrpperBtnImport {
  display: flex;
  justify-content: space-between;
}
.columError {
  color: red;
}
.columSuccess {
  color: green;
}
