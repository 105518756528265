.filters {
  white-space: nowrap;
}
.filters.secondRow {
  margin-top: 12px;
}
.filters .form-control {
  width: unset;
  display: unset;
}
.filters .filterClass select {
  width: 270px;
}
.filters .filterDate {
  margin: 0 !important;
}
.filters .filterDate input[type="date"] {
  width: 160px;
}
form .filterDate {
  margin: 0 !important;
}
.formLine2 {
  width: 100%;
  margin-top: 15px;
}
.formLine2 .wraperFilter {
  width: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}
.formLine2 .wraperBtn {
  display: flex;
}
