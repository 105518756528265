.bookReportScoContainer {
  margin-top: 0.8em;
  padding: 0;
}
.bookReportScoContainer .panel-heading .row {
  margin: 0;
}
.bookReportScoContainer .panel-body.default {
  display: block;
}
.bookReportScoContainer .panel-body.student {
  display: none;
}
.bookReportScoContainer.rect_puzzle_6 .panel-body,
.bookReportScoContainer.rect_puzzle_8 .panel-body,
.bookReportScoContainer.rect_puzzle_15 .panel-body,
.bookReportScoContainer.rect_puzzle_16 .panel-body {
  display: flex;
  justify-content: center;
}
.bookReportScoContainer.rect_puzzle_6 .task-second-image-container,
.bookReportScoContainer.rect_puzzle_8 .task-second-image-container,
.bookReportScoContainer.rect_puzzle_15 .task-second-image-container,
.bookReportScoContainer.rect_puzzle_16 .task-second-image-container,
.bookReportScoContainer.rect_puzzle_6 .targets-container,
.bookReportScoContainer.rect_puzzle_8 .targets-container,
.bookReportScoContainer.rect_puzzle_15 .targets-container,
.bookReportScoContainer.rect_puzzle_16 .targets-container,
.bookReportScoContainer.rect_puzzle_6 .matches,
.bookReportScoContainer.rect_puzzle_8 .matches,
.bookReportScoContainer.rect_puzzle_15 .matches,
.bookReportScoContainer.rect_puzzle_16 .matches,
.bookReportScoContainer.rect_puzzle_6 .svg-container,
.bookReportScoContainer.rect_puzzle_8 .svg-container,
.bookReportScoContainer.rect_puzzle_15 .svg-container,
.bookReportScoContainer.rect_puzzle_16 .svg-container {
  display: none;
}
.bookReportScoContainer .passage-image-container img {
  width: 100%;
  object-fit: contain;
}
.bookReportScoContainer .audio-container,
.bookReportScoContainer .vms_icon_button {
  word-spacing: normal;
  align-items: flex-start;
  text-align: center;
  color: buttontext;
  background-color: buttonface;
  box-sizing: border-box;
  padding: 2px 6px 3px;
  border-width: 2px;
  border-color: buttonface;
  border-style: outset;
  -webkit-appearance: button;
  cursor: pointer;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
}
.activityTableWrapper {
  padding: 0;
}
.activityTableWrapper .icon {
  width: 1.5em;
  height: 1.5em;
  margin: 0.3em auto;
  background-size: contain;
  background-repeat: no-repeat;
}
.activityTableWrapper .icon svg,
.activityTableWrapper .icon g {
  fill: #29285E;
  width: 100%;
  height: 100%;
}
.activityTableWrapper tr.active svg,
.activityTableWrapper tr.active g {
  fill: #fff !important;
}
.assignBlocks .row {
  margin: 0 !important;
  padding: 0 !important;
}
