.chart-legend,
.bar-legend,
.line-legend,
.pie-legend,
.radar-legend,
.polararea-legend,
.doughnut-legend {
  list-style-type: none;
  margin-top: 5px;
  text-align: center;
  /* NOTE: Browsers automatically add 40px of padding-left to all lists, so we should offset that, otherwise the legend is off-center */
  -webkit-padding-start: 0;
  /* Webkit */
  -moz-padding-start: 0;
  /* Mozilla */
  padding-left: 0;
  /* IE (handles all cases, really, but we should also include the vendor-specific properties just to be safe) */
}
.chart-legend li,
.bar-legend li,
.line-legend li,
.pie-legend li,
.radar-legend li,
.polararea-legend li,
.doughnut-legend li {
  display: inline-block;
  white-space: nowrap;
  position: relative;
  margin-bottom: 4px;
  border-radius: 5px;
  padding: 2px 8px 2px 28px;
  font-size: smaller;
  cursor: default;
}
.chart-legend li span,
.bar-legend li span,
.line-legend li span,
.pie-legend li span,
.radar-legend li span,
.polararea-legend li span,
.doughnut-legend li span {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 5px;
}
.main_container {
  width: 53.16em;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.32);
  overflow: hidden;
}
.panel_buttons {
  width: 100%;
}
.panel_buttons .btn {
  width: 50%;
}
.main_block.desctop {
  margin-top: 5em;
}
.main_block h1 {
  margin-left: 1.4em;
  font-weight: normal;
  margin: 0.7em 0 0.2em;
  font-size: 1em;
}
#bookChart,
#pageChart,
#readingChart {
  background: #ffffff;
}
.container_canvas_summary {
  float: left;
  width: 25em !important;
  position: relative;
  left: 0.5em;
}
.container_canvas.right {
  margin: 0 0 0 1em;
}
.container_canvas_reading {
  float: left;
  width: 51em!important;
  position: relative;
  left: 0.5em;
}
.main_container.hide {
  opacity: 0;
}
.loading_charts_message {
  margin: 6em auto 0;
  text-align: center;
  font-size: 2em;
}
.panel_buttons .btn-default:hover,
.panel_buttons .btn-default.active-tab {
  color: #fff !important;
  background: #29285E !important;
}
@media (max-width: 768px) {
  .main_container {
    width: 100%;
  }
  .panel_buttons {
    left: 0;
  }
  .panel_buttons .btn {
    width: 100%;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .main_container.summary .desctop {
    display: block!important;
  }
  .main_container.summary .mobile {
    display: none!important;
  }
}
