.certificates_report_container .filters {
  white-space: nowrap;
}
.certificates_report_container .filters.secondRow {
  margin-top: 12px;
}
.certificates_report_container .filters .form-control {
  width: unset;
  display: unset;
}
.certificates_report_container .filters .filterClass select {
  width: 270px;
}
.certificates_report_container .filters .filterDate input[type="date"] {
  width: 160px;
}
.certificates_report_container .filters textarea {
  vertical-align: top;
  min-width: 180px;
  min-height: 34px;
  height: 34px;
  width: 25%;
  max-width: 400px;
}
.certificates_report_container .filters button {
  min-width: 80px;
  vertical-align: top;
}
.certificates_report_container .resultTable {
  margin-top: 12px;
}
.certificates_report_container .resultTable p {
  text-align: center;
  color: #29285E;
  font-size: 1.45em;
}
.certificates_report_container .resultTable td.action {
  text-align: center;
  min-width: 74px;
}
.certificate_details_container .topPanel button {
  position: relative;
  z-index: 100;
}
.certificate_details_container .resultTable {
  position: relative;
  top: -28px;
}
.certificate_details_container .resultTable p {
  text-align: center;
  color: #29285E;
  font-size: 1.45em;
}
