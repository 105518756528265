.audiocourse .vms_idevice * {
  pointer-events: none;
}
.audiocourse .speed-control {
  background-image: url('course/images/background_scrollbar.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 12em;
  height: 1.5em;
  float: left;
  z-index: 10;
  margin: 1.7em 1.1em 0 2em;
  position: relative;
  box-sizing: content-box;
  display: table;
}
.audiocourse .audioplayer_controls_container {
  position: absolute;
  height: 4em;
  z-index: 900;
  width: 100%;
}
.audiocourse .audioplayer_controls_container [class^="col"] {
  padding: 0;
}
.audiocourse .audioplayer_controls_container .wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.audiocourse .audioplayer_controls_container .wrapper .widthRange {
  width: 40%;
}
.audiocourse .audioplayer_controls_container .wrapper div:first-child {
  margin-right: 1em;
}
.audiocourse .audioplayer_controls_container .wrapper div:last-child {
  margin-left: 1em;
}
.audiocourse .wrapperSpeed {
  display: table-cell;
  vertical-align: middle;
}
.audiocourse .audioPlayerRange {
  -webkit-appearance: none;
  border-radius: 0.2em;
  box-shadow: inset 0 0 0.5em grey;
  background: #939393;
  height: 0.6em;
  width: 8.6em;
  outline: 0 transparent;
  margin: 0 auto;
  display: flex;
  box-sizing: content-box;
  border: none;
  font-size: small;
}
.audiocourse button.play-pause.play {
  background-image: url('course/images/play_audio.png');
}
.audiocourse button.play-pause.play,
.audiocourse button.play-pause {
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  width: 5em;
  height: 5em;
  float: left;
}
.audiocourse button.play-pause {
  background-image: url('course/images/stop_audio.png');
}
.audiocourse .progressbar_container {
  height: 3.2em;
  padding-top: 0.55em;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
}
.audiocourse .progressbar_container > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.audiocourse .change_activity {
  -webkit-appearance: none;
  border-radius: 0.2em;
  box-shadow: inset 0 0 0.5em #261404;
  background: #532d0a;
  height: 1.6em;
  border-radius: 2em;
  float: left;
  outline: 0 transparent;
  margin: 1.2em 0;
  box-shadow: 0.05em 0.05em 1.2em -0.03em #242324;
  border: none;
  font-size: small;
}
.audiocourse .previousGroup {
  background-image: url('course/images/previous_active.png');
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.audiocourse .nextGroup,
.audiocourse .previousGroup {
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  width: 3.5em;
  height: 3.5em;
  margin-top: 0.1em;
}
.audiocourse .nextGroup {
  background-image: url('course/images/previous_active.png');
  margin-left: 0.8em;
}
.audiocourse .speed-control input[type='range']::-webkit-slider-thumb,
.audiocourse .widthRange input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #ecf0f1;
  border: 0.05em solid #ad5504;
  width: 1em;
  height: 1.5em;
  cursor: pointer;
  -webkit-box-shadow: 0.05em 0.05em 3em -0.03em #242324;
  -moz-box-shadow: 0.05em 0.05em 3em -0.03em #242324;
  box-shadow: 0.05em 0.05em 3em -0.03em #242324;
}
.audiocourse .speed-control .change_activity[type='range']::-webkit-slider-thumb,
.audiocourse .widthRange .change_activity[type='range']::-webkit-slider-thumb {
  height: 2em;
}
.audiocourse button {
  outline: none;
}
.audiocourse .previousGroup:disabled {
  background-image: url('course/images/previous_inactive.png');
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.audiocourse .nextGroup:disabled {
  background-image: url('course/images/previous_inactive.png');
  margin-left: 0.8em;
}
