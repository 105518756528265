.formSchool .CleverLoad {
  position: relative;
}
.formSchool .CleverLoad .loadCleverBtn {
  position: absolute;
  top: 2px;
  right: -4em;
  width: 3.5em;
  padding: 0;
}
.formSchool .ui-select-container[disabled],
.formSchool .ui-select-match[disabled] {
  opacity: 1;
}
.formSchool .form-control[disabled] {
  background-color: #ffffff;
}
.ui-select-bootstrap .ui-select-toggle > a.btn:hover {
  background-color: transparent;
}
