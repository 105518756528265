.TeacherMode_Task {
  margin-bottom: 1em;
}
.TeacherMode_Task .wrapperBlockTabs {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-left: 15px;
}
.TeacherMode_Task .wrapperBlockTabs .wrappTab2 {
  display: flex;
  position: relative;
  bottom: -4px;
}
.TeacherMode_Task .wrapperBlockTabs .wrappTab2 .tab {
  padding: 7px 14px;
  margin-right: 9px;
  border-radius: 9px 9px 0 0;
  border-bottom: none;
  cursor: pointer;
  background-color: #ededed;
  font-size: 1.1em;
}
.TeacherMode_Task .wrapperBlockTabs .wrappTab2 .tab.active {
  background: #ffffff;
  border: 4px solid #29285E;
  border-bottom: 0;
  z-index: 12;
}
.TeacherMode_Task .line {
  width: inherit;
  height: 4px;
  background-color: #29285E;
  position: relative;
  z-index: 10;
  border: none;
  margin: 0;
}
.Form .field {
  display: flex;
  width: 100%;
  margin-bottom: 1em;
}
.Form .field .titleField {
  width: 30%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 1.3em;
  box-sizing: border-box;
}
.Form .field input[type='date'],
.Form .field input[type='text'],
.Form .field textarea {
  width: 70%;
}
.popupTask {
  position: fixed;
  left: 50%;
  top: 81px;
  border: 2px solid #29285E;
  border-radius: 0.5em;
  width: 40em;
  margin-left: -20em;
  flex-direction: column;
  display: flex;
  padding: 2em;
  z-index: 1002;
  background: white;
}
.popupTask .field {
  display: flex;
  width: 100%;
  margin-bottom: 1em;
}
.popupTask .field .titleField {
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1.3em;
  box-sizing: border-box;
}
.popupTask .field input[type='date'],
.popupTask .field input[type='text'],
.popupTask .field textarea {
  width: 70%;
}
.popupTask .fieldBtn {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.popupTask .fieldBtn button {
  margin-left: 0.5em;
}
tr.redline,
.linkaddteacher .redline {
  color: red;
}
.linkaddteacher .greenline {
  color: green;
}
.addBlockPopup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #dedede1f;
  z-index: 1001;
}
.assignBlocks.paddingClass {
  padding: 0 15px;
}
