.start_page_wrap {
  width: 100%;
  height: 100vh;
  display: flex;
}
.start_page_wrap .btnStart {
  outline: none;
  border: none;
  background: #29285E;
  color: #ffffff;
  width: 10em;
  margin: auto;
  padding: 0.5em 1em;
  font-size: 22px;
  border-radius: 0.3em;
  cursor: pointer;
}
