.popupProfile {
  width: 1.3em;
  cursor: pointer;
}
button {
  outline: none;
}
.parenttitle {
  font-size: 1.15em;
  text-align: right;
}
.modal,
.modal-open {
  padding: 0 !important;
}
.edit_profile .password {
  position: relative;
}
.edit_profile .password .control {
  z-index: 2;
}
.edit_profile .password .formsg {
  position: absolute;
  right: 0;
  z-index: 0;
}
.edit_profile .checkForError.errorForForm {
  font-size: 14px !important;
  text-align: left !important;
}
.edit_profile .checkForError {
  font-size: 0 !important;
}
.edit_profile .modal-header {
  padding: 8px;
}
.edit_profile .input_controls {
  /*font-family: 'Open Sans', sans-serif;*/
}
.edit_profile .input_controls h3 {
  margin-bottom: 25px;
}
.edit_profile .input_controls p {
  text-align: right;
  height: 100%;
  margin: 0;
  font-size: 1.15em;
}
.edit_profile .input_controls input,
.edit_profile .input_controls select {
  background: #ffffff !important;
  width: 100%;
  height: 34px !important;
  padding: 6px 12px !important;
  line-height: 1.42857143;
}
.edit_profile .input_controls input[type="checkbox"] {
  width: 20px;
}
.edit_profile .input_controls .save {
  opacity: 1;
  background: #eaba1f;
  width: 80%;
  padding: 0.5em 0;
  font-size: 1.1em;
  color: #000000;
}
.edit_profile .input_controls .msgsaved {
  text-align: center !important;
  margin: 1em auto;
  -moz-animation: cssAnimation 1s linear 2s forwards;
  /* Firefox */
  -webkit-animation: cssAnimation 1s linear 2s forwards;
  /* Safari and Chrome */
  -o-animation: cssAnimation 1s linear 2s forwards;
  /* Opera */
  animation: cssAnimation 1s linear 2s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@keyframes cssAnimation {
  to {
    opacity: 0;
  }
}
@-webkit-keyframes cssAnimation {
  to {
    opacity: 0;
  }
}
.edit_profile .input_controls .formsg {
  margin: 0;
}
.edit_profile .input_controls .formsg span {
  float: right;
  color: red;
}
.edit_profile .input_controls .showmsg {
  opacity: 0;
  height: 100%;
  width: 100%;
  -moz-animation: errorMsg 1s linear 0s forwards;
  /* Firefox */
  -webkit-animation: errorMsg 1s linear 0s forwards;
  /* Safari and Chrome */
  -o-animation: errorMsg 1s linear 0s forwards;
  /* Opera */
  animation: errorMsg 1s linear 0s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@keyframes errorMsg {
  to {
    opacity: 1;
    height: 100%;
    width: 100%;
  }
}
@-webkit-keyframes errorMsg {
  to {
    opacity: 1;
    height: 100%;
    width: 100%;
  }
}
.edit_profile .input_controls .hidemsg {
  opacity: 1;
  height: 0;
  width: 0;
  -moz-animation: hideerrorMsg 1s linear 0s forwards;
  /* Firefox */
  -webkit-animation: hideerrorMsg 1s linear 0s forwards;
  /* Safari and Chrome */
  -o-animation: hideerrorMsg 1s linear 0s forwards;
  /* Opera */
  animation: hideerrorMsg 1s linear 0s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@keyframes hideerrorMsg {
  to {
    opacity: 0;
    height: 0;
    width: 0;
  }
}
@-webkit-keyframes hideerrorMsg {
  to {
    opacity: 0;
    height: 0;
    width: 0;
  }
}
.edit_profile .input_controls > div {
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container.orderblock {
  padding: 0;
  font-size: 15px;
  width: 98%;
}
.container.orderblock.licenses {
  margin: auto;
}
.container.orderblock .title_order p {
  display: inline-block;
  float: left;
}
.container.orderblock .block_cart_product {
  height: 100%;
}
.container.orderblock .wrapperInfoOrder {
  width: 100%;
  height: 100%;
  position: relative;
}
.container.orderblock .wrapperInfoOrder .info_order {
  width: 20%;
  float: left;
  text-align: center;
}
.container.orderblock .wrapperInfoOrder .info_order.one {
  height: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding-left: 1em;
}
.container.orderblock .wrapperInfoOrder .info_order.one p:first-child {
  display: flex;
  margin: 0;
  width: 100%;
}
.container.orderblock .wrapperInfoOrder .info_order.one p:last-child {
  display: flex;
  margin: 0;
  align-items: flex-end;
  width: 100%;
}
.container.orderblock .wrapperInfoOrder .info_order.three {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.container.orderblock .wrapperInfoOrder .info_order.five {
  text-align: right;
}
.container.orderblock .title_order {
  width: 98%;
  margin: auto;
  display: flex;
}
.container.orderblock .title_order .info_order {
  display: inline-flex;
  flex-grow: 1;
}
.container.orderblock .table {
  display: table;
}
.container.orderblock .table .row {
  display: block;
  box-sizing: border-box;
  border-collapse: separate;
  margin: 0;
}
.container.orderblock .table .row.wrapper {
  padding: 0 1em 1em;
}
.container.orderblock .table .row.header {
  color: #8e8e8e;
  padding: 0 1em 1em;
  padding-bottom: 0;
}
.container.orderblock .table .row .cell:first-child {
  text-align: left;
  width: 40%;
}
.container.orderblock .table .row .cell {
  display: inline-block;
  float: left;
  width: 15%;
  vertical-align: top;
  position: relative;
  text-align: center;
  font-size: 1em;
  min-height: 0.01em;
}
.container.orderblock .table .row .cell .cart_img {
  float: left;
  height: 10em;
  width: 10em;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.container.orderblock .table .row .cell .cart_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
.container.orderblock .table .row .cell .blockNamebook {
  margin-left: 11em;
  /*p:first-child{                            
                            position: absolute;
                            top: 0em;
                            left: 11em;
                            margin: 0;
                        }
                        p:last-child{
                            position: absolute;
                            bottom: 0em;
                            left: 11em;
                            margin: 0;
                        }*/
}
.container.orderblock .table .row .cell .blockNamebook .productname {
  margin-bottom: 4.7em;
}
.container.orderblock .table .row .cell.center {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.container.orderblock .table .row .cell.bold {
  font-weight: bold;
}
.lineOrder {
  width: 100%;
  border-top: 3px solid #d3d3d3;
  position: relative;
  margin-bottom: 1em;
}
.sort.market {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Capa_1' x='0px' y='0px' width='512px' height='512px' viewBox='0 0 402.577 402.577' style='enable-background:new 0 0 402.577 402.577;' xml:space='preserve'>\
<g>	<path d='M400.858,11.427c-3.241-7.421-8.85-11.132-16.854-11.136H18.564c-7.993,0-13.61,3.715-16.846,11.136   c-3.234,7.801-1.903,14.467,3.999,19.985l140.757,140.753v138.755c0,4.955,1.809,9.232,5.424,12.854l73.085,73.083   c3.429,3.614,7.71,5.428,12.851,5.428c2.282,0,4.66-0.479,7.135-1.43c7.426-3.238,11.14-8.851,11.14-16.845V172.166L396.861,31.413   C402.765,25.895,404.093,19.231,400.858,11.427z' fill='#29285E'/>\
</g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>\
<g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>");
}
.desctopOrders {
  display: block;
}
.mobileOrders {
  display: none;
  position: relative;
}
.mobileOrders .sort.market {
  width: 1em;
  height: 1em;
  position: relative;
  right: -1em;
}
.mobileOrders .wrapperOrderList {
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.4em 1em;
}
.mobileOrders .wrapperOrderList .wrapperColumn {
  width: 100%;
  margin-bottom: 1em;
  height: 100%;
}
.mobileOrders .wrapperOrderList .wrapperColumn .leftColumn {
  width: 40%;
  display: inline-block;
  box-sizing: border-box;
  padding-bottom: 0.3em;
}
.mobileOrders .wrapperOrderList .wrapperColumn .rightColumn {
  display: inline-block;
  width: 50%;
  box-sizing: border-box;
  padding-bottom: 0.3em;
  font-weight: bold;
}
.mobileOrders .row {
  margin: 0 !important;
}
.mobileOrders .row .cell.margin {
  margin: 0.5em 0;
  position: relative;
}
.mobileOrders .row .cart_img {
  display: inline-flex;
  width: 11em;
  height: 11em;
  margin-right: 0.5em;
}
.mobileOrders .row .cart_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
.mobileOrders .row .blockNamebook {
  display: inline-block;
  vertical-align: top;
  width: 50%;
}
.container.orderblock .table .row .cell.subsribtions:first-child {
  width: 65%;
}
.blockNamebook2 {
  height: 10em;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.blockNamebook2 button {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #eaba1f;
  border: 0;
  border-radius: 4px;
  font-weight: bold;
  padding: 6px 12px;
  cursor: pointer;
}
.container.orderblock .table .row .cell.right {
  width: 20%;
  justify-content: flex-end;
  text-align: right;
}
.unsubscript {
  background: #eaba1f;
  border: 0;
  border-radius: 4px;
  font-weight: bold;
  padding: 4px;
  cursor: pointer;
}
.wrapper_parenttitle .alignCenter {
  display: inline-flex;
  align-items: center;
}
.wrapper_parenttitle .alignCenter span {
  margin-left: 0.3em;
}
.infinity {
  font-size: 21px;
  position: relative;
  top: 3px;
}
