.calendar-container {
  width: 100%;
  height: auto;
  margin: 0 auto;
  border: 0.01em solid #E4E4E4;
}
.main_calendar {
  width: 70%;
  margin: 0 auto;
  background: #ffffff;
  padding: 0;
  border-radius: 1em;
}
.container_nameDate {
  text-align: center;
  width: 21em;
  max-width: 21em;
  height: 2em;
  padding-top: 1em;
  margin: 0 auto 2em;
}
.container_nameDate span {
  font-weight: bold;
  position: relative;
  top: 0.3em;
  text-transform: capitalize ;
}
.calendar-item {
  display: inline-block;
  width: 14.28%;
  height: 3em;
  text-align: center;
  vertical-align: middle;
  outline: 1px solid #E4E4E4;
  position: relative;
  line-height: 3em;
}
.calendar-item.days {
  background: #E4E4E4;
  color: #000;
  text-transform: capitalize;
  font-weight: bold;
}
.sunday_column,
.day-0 {
  color: red !important;
}
.saturday_column,
.day-6 {
  color: #29285E !important;
}
.attempt {
  position: absolute;
  width: 1em;
  height: 1em;
  border-radius: 1em;
  top: 0;
  left: 0;
}
.legend {
  margin-top: 35px;
  display: table;
  width: 100%;
  margin-left: 6%;
}
.legend-item {
  display: table-cell;
  width: 33%;
}
.legend .attempt {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.yellow {
  background: #fcd461;
}
.green {
  background: #8ac85f;
}
.blue {
  background: #68cfef;
}
.monday {
  margin-left: 14.28%;
}
.tuesday {
  margin-left: 28.56%;
}
.wednesday {
  margin-left: 42.84%;
}
.thursday {
  margin-left: 57.12%;
}
.friday {
  margin-left: 71.4%;
}
.saturday {
  margin-left: 85.68%;
}
.today {
  background: #ffe4b5;
}
@media (max-width: 768px) {
  .container_nameDate {
    text-align: center;
    width: 90%;
    max-width: 90%;
    margin: 0 auto 2em;
  }
}
