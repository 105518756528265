.mainblock.course {
  display: flex;
  position: relative;
  background: #fff;
  z-index: 1111;
}
.audiocourse {
  position: relative;
  height: 100vh;
  background: #fff;
  z-index: 1111;
}
#full_screen.languaBooksCourse {
  position: fixed;
  transition: initial;
  width: 100vw;
  height: 100vh;
  height: -webkit-fill-available;
}
.testAssesment {
  position: absolute;
  top: 0em;
  left: 0;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 10em;
  overflow: hidden;
}
.testAssesment.disabled {
  pointer-events: none;
}
.testAssesment .triangle {
  background: rgba(255, 255, 255, 0.48);
  position: absolute;
  bottom: -1em;
  right: -2em;
  width: 5em;
  height: 3em;
  transform: rotate(-45deg);
}
.testAssesment .triangle p {
  margin: 0;
  text-align: center;
  transform: rotate(45deg);
  color: #ffffff;
  font-weight: bold;
  font-size: 1.2em;
  text-shadow: 1px 1px 2px black, 0 0px 10px #0c0c0c;
}
.wrapperStars,
.wrapperDiamond {
  width: 32px;
  height: 30px;
  margin: auto;
  transform: rotate(45deg);
  position: relative;
  top: -4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapperStars span,
.wrapperDiamond span {
  position: absolute;
  font-weight: bold;
  color: #6b6b6b;
}
.wrapperStars svg path:first-child,
.wrapperDiamond svg path:first-child {
  fill-opacity: 0;
}
.wrapperStars svg path:last-child,
.wrapperDiamond svg path:last-child {
  fill: rgba(255, 255, 255, 0);
}
.wrapperDiamond {
  width: 24px !important;
  height: 26px !important;
  top: 0px;
}
.wrapperDiamond svg path {
  fill: #828282 !important;
  fill-opacity: 1 !important;
}
.wrapperСup {
  width: 20px;
  height: 24px;
  top: 2px;
  margin: auto;
  transform: rotate(45deg);
  position: relative;
}
.wrapperСup.gold path {
  fill: #eaba1f;
}
.desc {
  height: 14em;
  max-height: 14em;
  overflow-y: auto;
}
.wrapperTaskCourses {
  padding: 2em 2em;
}
.wrapperNavig {
  position: absolute;
  top: 21em;
  width: 100%;
  right: 0;
  height: 1.7em;
  object-fit: contain;
  z-index: 1;
}
.infoPrev {
  background-image: url("data:image/svg+xml;utf8, <svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 477.175 477.175' style='fill: rgb(41, 40, 94)' xml:space='preserve'><g style='fill: rgb(41, 40, 94)'><path d='M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225   c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z' style='fill: rgb(41, 40, 94)'></path></g></svg>");
  background-size: 100% 100%;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  width: 2em;
  height: 2em;
  cursor: pointer;
  float: left;
}
.infoNext {
  background-image: url("data:image/svg+xml;utf8, <svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 477.175 477.175' style='fill: rgb(41, 40, 94)' xml:space='preserve'><g style='fill: rgb(41, 40, 94)'><path d='M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5   c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z ' style='fill: rgb(41, 40, 94)'></path></g></svg>");
  background-size: 100% 100%;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
  width: 2em;
  height: 2em;
  cursor: pointer;
  float: right;
}
.info_img.disabled .wrapperInfoButton {
  opacity: 0.5;
  pointer-events: none;
}
.info_img.disabled img {
  pointer-events: none;
  opacity: 0.6;
}
.disabled_asses {
  opacity: 0.5;
  pointer-events: none;
}
.sort {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 511.626 511.627" style="enable-background:new 0 0 511.626 511.627;" xml:space="preserve">	<g><g><path d="M215.232,401.991h-54.818V9.136c0-2.663-0.854-4.856-2.568-6.567C156.133,0.859,153.946,0,151.279,0H96.461	c-2.663,0-4.856,0.855-6.567,2.568c-1.709,1.715-2.568,3.905-2.568,6.567v392.855H32.507c-4.184,0-7.039,1.902-8.563,5.708 	c-1.525,3.621-0.856,6.95,1.997,9.996l91.361,91.365c2.096,1.707,4.281,2.562,6.567,2.562c2.474,0,4.664-0.855,6.567-2.562  l91.076-91.078c1.906-2.279,2.856-4.571,2.856-6.844c0-2.676-0.854-4.859-2.568-6.584    C220.086,402.847,217.9,401.991,215.232,401.991z" fill="rgb(41, 40, 94)"/><path d="M428.511,479.082h-70.808c-3.997,0-6.852,0.191-8.559,0.568l-4.001,0.571v-0.571l3.142-3.142   c2.848-3.419,4.853-5.896,5.996-7.409l105.344-151.331v-25.406H297.744v65.377h34.263v-32.832h66.236   c3.422,0,6.283-0.288,8.555-0.855c0.572,0,1.287-0.048,2.143-0.145c0.853-0.085,1.475-0.144,1.852-0.144v0.855l-3.142,2.574    c-1.704,1.711-3.713,4.273-5.995,7.706L296.31,485.934v25.693h166.734v-66.521h-34.54v33.976H428.511z" fill="rgb(41, 40, 94)"/>	<path d="M468.475,189.008L402.807,0h-46.25l-65.664,189.008h-19.979v30.264h81.933v-30.264h-21.409l13.419-41.112h69.381 l13.415,41.112H406.25v30.264h82.228v-30.264H468.475z M354.278,116.487l20.841-62.241c0.76-2.285,1.479-5.046,2.143-8.28   c0.66-3.236,0.996-4.949,0.996-5.139l0.855-5.708h1.143c0,0.761,0.191,2.664,0.562,5.708l3.433,13.418l20.554,62.241H354.278z" fill="rgb(41, 40, 94)"/></g></g></svg> ');
  background-size: 100% 100%;
  width: 1.2em;
  height: 1.2em;
  float: right;
  cursor: pointer;
  display: inline-block;
  color: #7b8a8b !important;
}
.sort a.dropwown-toggle {
  width: 1.2em;
  height: 1.2em;
}
.sort a {
  width: 100%;
  height: 100%;
}
.nav.sort a:hover,
.nav.sort:hover {
  background-color: transparent;
  color: #337ab7;
}
.sort .dropdown-menu li:not(.noactive):hover {
  background: #29285E;
  color: #ffffff;
}
.sort .activeOrder {
  background: #f3f3f3;
  color: #29285E;
}
.sort .dropdown-menu li.noactive {
  color: #000000;
}
.nav.sort .dropdown-menu li.noactive {
  padding-left: 0.8em !important;
}
.dropdown-menu {
  right: 0 !important;
  left: inherit;
}
.nav.sort .open > a,
.nav.sort .open > a:hover,
.nav.sort .open > a:focus {
  background-color: transparent;
}
.wrapperTaskCourses .title_Course {
  margin-bottom: 0.4em;
}
.nav.sort .dropdown-menu li {
  border: 0.14em solid transparent;
  padding: 0.2em 0.8em 0.2em 1.3em !important;
  font-size: 1.15em;
  -webkit-transition: background 0.5s ease-in;
  -moz-transition: background 0.5s ease-in;
  -o-transition: background 0.5s ease-in;
  transition: background 0.5s ease-in;
  /*font-family: 'Open Sans', sans-serif;*/
}
.wrapInfoParams2 {
  width: 100%;
  font-size: 1.15em;
}
.wrapInfoParams2 .desc {
  font-size: 1em;
}
.msgNoCourses {
  text-align: center;
  font-size: 1.3em;
}
.infoAuthor {
  text-align: center;
  margin-bottom: 1em;
  font-size: 0.8em;
  font-weight: normal;
}
.blackZone .main_content_wrapper + ng-include .footer {
  display: none;
}
.no_attempts {
  font-size: 2em;
}
.no_attempts p {
  text-align: center;
}
.no_attempts .back {
  cursor: pointer;
}
