.idnumberLicense {
  background: gainsboro;
  padding: 0 5px;
  display: inline !important;
}
.ui-select-bootstrap .ui-select-choices-row > span .listIdnumber {
  background: #e9e8e8;
}
.ui-select-bootstrap .ui-select-choices-row.active > span .listIdnumber {
  background: #559cd9;
  color: #ffffff;
}
.notNature {
  border: 1px solid red !important;
}
.mainListLicenses .maintable {
  width: 100%;
  float: left;
  overflow-y: auto;
  overflow-x: hidden;
}
input.licensedata[disabled] {
  opacity: 0.7;
  background: #ffffff;
}
.wrapBlockUiSelect > p {
  width: 20%;
}
.wrapBlockUiSelect textarea.license {
  resize: none;
}
.wrapBlockUiSelect .license-quantity {
  width: 220px;
}
.licensedata {
  width: 220px;
  border-color: #29285E;
}
.licenseCheckboxExpired {
  display: inline-flex;
  margin-left: 10px;
  align-items: center;
}
.licenseCheckboxExpired label {
  margin-left: 4px;
  font-weight: normal;
}
.obsoleteLicense {
  margin-left: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.obsoleteLicense input {
  margin: 0;
  margin-right: 5px;
}
.license-purchases h3 {
  color: #29285e;
}
.license-purchases .totalTitle {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 18px;
}
.license-purchases .totalNumber {
  color: #29285e;
  font-size: 18px;
}
