/*------footer-----------*/
.imgSocial i {
  color: #29285E;
  transition: transform 0.2s linear;
}
.imgSocial i:hover {
  transform: scale(1.3);
  cursor: pointer;
}
.footer {
  height: 3.2em;
  margin-top: 1.3em;
  text-align: center;
}
.footer .container-fluid {
  background: #29285E;
  color: #ffffff;
  display: flex;
  align-items: center;
  height: 100%;
  /*font-family: 'Open Sans', sans-serif;*/
}
.footer .edu {
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: flex-end;
}
.footer .edu .img_logoeduspeak {
  background-image: url(images/logoEdu.png);
  height: 3.38em;
  float: left;
  width: 5em;
  background-size: contain;
  background-repeat: no-repeat;
}
.footer .edu span {
  font-size: 0.87em;
  /*font-family: 'Open Sans', sans-serif;*/
}
.imgSocial,
.errorContentWrapper {
  width: 100%;
  height: 4em;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 15em;
  box-sizing: border-box;
  margin-top: 1em;
  /*font-family: 'Open Sans', sans-serif;*/
}
.errorContentWrapper {
  width: 100%;
}
.errorContentWrapper p,
.errorContentWrapper a {
  font-size: 18pt;
}
.signatures {
  text-align: center;
  width: 100%;
  height: 1.7em;
  color: #FFFFFF;
  background: #29285E;
  padding: 0.1em 0;
  margin: 1em 0 0;
  border-top: 0.15em solid #D2D2D2;
}
.iconLion {
  background: url("images/lion.png");
  width: 4em;
  height: 5em;
  background-size: contain;
  background-repeat: no-repeat;
  float: left;
}
.wrapperlion {
  width: 100%;
  height: 6em;
  position: relative;
  display: inline-flex;
  justify-content: center;
}
.leftBlockLion {
  height: 3em;
  width: 30%;
  float: left;
  border-bottom: 0.1em solid #9B7920;
  margin-right: 5em;
}
.rightBlockLion {
  height: 3em;
  width: 30%;
  float: left;
  border-bottom: 0.1em solid #9B7920;
  margin-left: 5em;
  border-radius: 0 0.5em 0.5em 0;
}
@media (max-width: 990px) {
  .footer {
    /*text-align: center;
        margin-top: -3em;
        margin-bottom: 1em;*/
    margin-top: 0.3em;
    margin-bottom: 0.6em;
  }
}
