table {
  width: 100%;
  border-collapse: collapse;
}
tr {
  text-align: center;
}
th,
td {
  padding: 0.5em 1em;
}
td {
  cursor: pointer;
}
.directionQuest {
  display: flex;
  flex-direction: column;
  position: relative;
}
.directionQuest .wrapperQuestNavig {
  position: absolute;
  width: 100%;
  top: 5em;
  z-index: 3;
}
.directionQuest .wrapperQuestNavig button {
  width: 3em;
  height: 3em;
}
.directionQuest .wrapperQuestBook {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  padding: 0 1.1em;
}
.directionQuest .wrapperQuestBook .questBook {
  display: inline-block;
  vertical-align: top;
  width: 12em;
  height: 14.5em;
  margin: 0 1.2em 1em;
  position: relative;
  z-index: 4;
}
.directionQuest .wrapperQuestBook .questBook img {
  width: 12em;
  height: 12em;
  cursor: pointer;
  box-shadow: 0 0 0.3em #605f5f;
}
.directionQuest .wrapperQuestBook .questBook .statusBook {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0;
}
.directionQuest .wrapperQuestBook .questBook .statusBook .imgStatus {
  width: 2em;
  height: 2em;
  background: url('images/img_task_course_status_000.png');
  background-size: contain;
  background-repeat: no-repeat;
  outline: none;
  border: none;
}
.directionQuest .wrapperQuestBook .questBook .statusBook .imgStatus.inprogress {
  background: url('images/img_task_course_status_001.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.directionQuest .wrapperQuestBook .questBook .statusBook .imgStatus.completed {
  background: url('images/img_task_course_status_002.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.directionQuest .wrapperInfoQuest {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.directionQuest .wrapperInfoQuest .taskText {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  font-size: 1.3em;
  margin-bottom: 1em;
}
.directionQuest .wrapperInfoQuest .taskText .begintask,
.directionQuest .wrapperInfoQuest .taskText .endtask {
  width: 25%;
}
.directionQuest .wrapperInfoQuest .taskText .titleTask {
  min-width: 50%;
  text-align: center;
}
.directionQuest .wrapperInfoQuest .taskText .titleTask p {
  margin: 0;
}
.directionQuest .wrapperInfoQuest .taskText .titleTask p:first-child {
  font-weight: bold;
}
.directionQuest .wrapperInfoQuest .text {
  font-size: 1.15em;
  margin-bottom: 1.4em;
}
.directionQuest .wrapperProgressBlock {
  width: 50%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto 0;
  font-size: 1.2em;
}
.directionQuest .wrapperProgressBlock progress {
  border-radius: 7px;
  width: 30em;
  height: 1.57em;
  margin-bottom: 1em;
  margin-top: 0.5em;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}
.directionQuest .wrapperProgressBlock progress::-webkit-progress-bar {
  background-color: transparent;
  border-radius: 7px;
  border: 1px solid #29285E;
}
.directionQuest .wrapperProgressBlock progress::-webkit-progress-value {
  background-color: #29285E;
  border-radius: 7px;
}
.directionQuest .wrapperProgressBlock progress::-moz-progress-bar {
  /* style rules */
}
.directionQuest .wrapperProgressBlock section div {
  font-size: 1.05em;
}
.backButton {
  background: url('library/task/images/back.png');
  background-size: contain;
  background-repeat: no-repeat;
  outline: none;
  border: none;
  width: 3em;
  height: 3em;
  cursor: pointer;
  position: absolute;
  left: 29em;
  top: 0em;
}
.msgNoTasks {
  font-size: 1.25em;
  text-align: center;
}
/*----*/
@keyframes example {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  35% {
    -webkit-transform: rotate(3deg);
    transform: rotate(3deg);
  }
  55% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  70% {
    -webkit-transform: rotate(-3deg);
    transform: rotate(-3deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
.wrapperCourseShelve .titleTask {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  color: #000000;
}
.wrapperCourseShelve .taskText {
  font-weight: 300;
  color: #2d2d2d;
  font-size: 16px;
  line-height: 24px;
}
.listTaskQuiz {
  width: 2.3em;
  height: 2.5em;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0.2em 0;
  margin-right: 0.3em;
  text-align: center;
  float: right;
  background-image: url("data:image/svg+xml;utf8,\
        <svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 33 33' style='enable-background:new 0 0 33 33;' xml:space='preserve'>\
        <g><path d='M16.5,30.5c-0.147,0-0.288-0.065-0.382-0.178l-16-19C-0.032,11.144-0.04,10.886,0.1,10.7l6-8   c0.094-0.126,0.243-0.2,0.4-0.2h20c0.157,0,0.306,0.074,0.4,0.2l6,8c0.14,0.186,0.132,0.444-0.018,0.622l-16,19   C16.788,30.435,16.647,30.5,16.5,30.5z M1.139,10.982L16.5,29.224l15.361-18.242L26.25,3.5H6.75L1.139,10.982z' fill='rgb(14, 5, 154)'>\
        </path></g></svg>");
}
.listTaskRead {
  width: 2.3em;
  height: 2.5em;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 0.2em 0;
  margin-right: 0.3em;
  text-align: center;
  float: right;
  background-image: url("data:image/svg+xml;utf8,\
        <svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Capa_1' x='0px' y='0px' viewBox='0 0 33 33' style='enable-background:new 0 0 33 33;' xml:space='preserve'>\
        <g><path d='M16.5,30.5c-0.147,0-0.288-0.065-0.382-0.178l-16-19C-0.032,11.144-0.04,10.886,0.1,10.7l6-8   c0.094-0.126,0.243-0.2,0.4-0.2h20c0.157,0,0.306,0.074,0.4,0.2l6,8c0.14,0.186,0.132,0.444-0.018,0.622l-16,19   C16.788,30.435,16.647,30.5,16.5,30.5z M1.139,10.982L16.5,29.224l15.361-18.242L26.25,3.5H6.75L1.139,10.982z' fill='rgb(1, 156, 20)'>\
        </path></g></svg>");
}
.wrapperCourseShelve.tasks {
  text-align: inherit;
}
.wrapperCourseShelve.tasks .taskText {
  display: inline-block;
  width: 50%;
}
.wrapperCourseShelve.tasks .taskRightBlock {
  display: inline-block;
  vertical-align: top;
  width: 48%;
  text-align: right;
}
.totalBook,
.completedBook {
  display: inline-block;
  margin: 0 1.5em 0.5em;
}
.wrapperTopBlock {
  height: 50%;
  max-height: 50%;
  margin: 0.8em 0 0.4em;
}
.wrapperTaskDesc {
  width: 100%;
  font-size: 16px;
}
.wrapperCourseShelve.tasks .titleTask {
  margin: 0 0 0.5em;
}
.completedBook {
  margin-right: 0;
}
