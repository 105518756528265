.imgOpenBook {
  width: 3em;
  height: 3em;
  background: url('images/openBooks.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.imgBooks {
  width: 3em;
  height: 3em;
  background: url('images/books.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.descCourse {
  line-height: 24px;
  margin-top: 0.5em;
  color: #2d2d2d;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.wrapperDesc {
  width: 70%;
  text-align: left;
  float: left;
  padding-top: 1em;
  padding-right: 1.3em;
  box-sizing: border-box;
}
.titleCourse {
  font-size: 1.3em;
  color: #29285E;
  font-weight: bold;
  /*padding-left: 0.5em;*/
  min-height: 2.3em;
}
.wrapperImgCollect {
  width: 30%;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
}
.wrapperCourseShelve img {
  width: 100%;
  height: 14em;
  object-fit: contain;
  cursor: pointer;
  float: right;
}
@media (max-width: 990px) {
  .wrapperDesc {
    text-align: center;
    margin: 0;
  }
}
@media (max-width: 1024px) and (max-height: 768px) and (orientation: landscape) {
  .descCourse {
    max-height: none;
  }
}
.notificationContainer {
  height: 97%;
  overflow-y: auto;
  max-height: 80vh;
  padding: 2em;
}
.notificationContainer .notification {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  padding: 1em 1.8em;
  margin-bottom: 25px;
}
.notificationContainer .notification .titleblock {
  font-weight: 600;
  margin-bottom: 1em;
}
